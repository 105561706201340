import { t, pluralize } from 'shared/i18n';
import noop from 'lodash/noop';
import CookieCutter from '@sqs/cookie-cutter';
import AccessPermissions from '@sqs/enums/AccessPermissions';
import BlockType from '@sqs/enums/BlockType';
import CommentSortTypes from '@sqs/enums/CommentSortTypes';
import CommentStates from '@sqs/enums/CommentStates';
import CommentStatuses from '@sqs/enums/CommentStatuses';
import CommentTargetTypes from '@sqs/enums/CommentTargetTypes';
import { Sanitizer } from 'src/main/webapp/universal/scripts-thirdparty/caja_html_sanitizer/html-sanitizer';
import evaluateJsonTemplate from 'shared/utils/evaluateJsonTemplate';
// eslint-disable-next-line import/no-unresolved
import '../styles-compressed/legacy/comments.css';

/**
* The Squarespace comment system
* @module squarespace-comments
*/
YUI.add('squarespace-comments', function (Y) {

  Y.namespace('Squarespace');

  /**
  * @namespace Squarespace
  * @class Comments
  */
  Y.Squarespace.Comments = Class.create({

    initialize: function (params) {
      this.events = [];

      this.settings = {
        likesAllowed: params.likesAllowed,
        flagsAllowed: params.flagsAllowed,
        threaded: params.threaded,
        approvalRequired: params.approvalRequired,
        allowAnon: params.allowAnon,
        avatarsHidden: !params.avatarsOn,
        readOnly: params.readOnly };


      this.commentData = new Y.Squarespace.CommentData({
        target: params.identifier,
        targetType: params.type,
        publicCommentCount: params.publicCommentCount,
        settings: this.settings,
        formatter: Y.bind(this.formatData, this) });


      this.localizedStrings = {
        addComment: t("Add Comment"),


        approve: t("Approve"),


        awaitingModeration: t("Awaiting Moderation"),


        comments: t("Comments"),


        commentsRestricted: t("Comments Restricted"),


        delete: t("Delete"),


        edit: t("Edit"),


        leastLiked: t("Least Liked"),


        leaveACommentHere: t("Leave a comment here."),


        lessLiked: t("Less Liked"),


        like: t("Like"),


        likes: t("Likes"),


        mostLiked: t("Most Liked"),


        newestFirst: t("Newest First"),


        oldestFirst: t("Oldest First"),


        pending: t("Pending"),


        preview: t("Preview"),


        postComment: t("Post Comment\u2026"),


        postReply: t("Post Reply"),


        reply: t("Reply"),


        replyToThisCommentHere: t("Reply to this comment here."),


        report: t("Report"),


        unmoderated: t("Unmoderated") };




      this.targetId = params.identifier;

      this.params = params || { design: {} };

      this.replyCompleteEvents = {};

      this.threadState = false;

      this.lastSortTime = new Date();
      this.lastHashChangeTime = new Date();
      this.PaginationModes = {
        // Extend mode means the user must click a button to add more comments at the bottom level
        EXTEND: 1,

        // Page mode is traditional pagination
        PAGE: 2 };


      this.containerEl = Y.one(this.params.containerEl);

      this.params.paginationMode = this.PaginationModes.EXTEND;

      this.type = BlockType.COMMENT;

      // The current page we're on in real pagination.
      this.currentPage = 1;

      // The amount of comments we start with, and the amount of comments we increment
      // by when adding to the list.
      this.maxComments = 50;

      // Map from sort order strings to sort type constants for translating
      // option field selections to actual sort types.
      this.sortOrderLookup = [null];
      this.sortOrderLookup.push(
      this.localizedStrings.oldestFirst,
      this.localizedStrings.newestFirst,
      this.localizedStrings.mostLiked,
      this.localizedStrings.leastLiked);


      // Current sort order
      this.sortOrder = this.params.sortType;

      // Container for list of comments.
      this.commentsEl = Y.Node.create('<div class="comments-content">' +
      '<div class="comment-list"></div></div>');

      if (this.settings.readOnly || !this.settings.allowAnon) {
        this.commentsEl.addClass('read-only');
      }
      this.commentsEl.appendTo(this.containerEl);

      this.loadMoreNode = Y.Node.create('<div class="load-more"><div class="view-more-btn">' + t("Load More Comments") +



      '</div></div>');

      this.fullViewNode = Y.Node.create('<div class="full-view-btn">' + t("View full comment thread") +

      '</div>');

      this.history = new Y.HistoryHash();

      // Delegate events so we don't have a bazillion event handlers in memory
      // also because some of these components are re-rendered during the comments
      // workflow.
      this.events.push(
      this.commentsEl.delegate('click', this.onReplyToggleClick, '.reply', this),
      this.commentsEl.delegate('click', this.onLikeClick, '.comment:not(.liked):not(.read-only) .like', this),
      this.commentsEl.delegate('click', this.onUnlikeClick, '.comment.liked:not(.read-only) .like', this),
      this.commentsEl.delegate('click', this.onFlagClick, '.comment:not(.flagged):not(.read-only) .flag', this),
      this.commentsEl.delegate('click', this.onUnflagClick, '.comment.flagged:not(.read-only) .flag', this),
      this.commentsEl.delegate('click', this.onApproveClick, '.comment.unmoderated:not(.read-only) .approve', this),
      this.commentsEl.delegate('click', this.onDeleteClick, '.comment:not(.read-only) .delete', this),
      this.commentsEl.delegate('click', this.replyBtnOnClick,
      '.reply-area-wrapper .comment-btn:not(.read-only)', this),
      this.commentsEl.delegate('click', this.togglePreview, '.reply-area-wrapper .preview-comment', this),
      this.history.on('commentIdChange', this.commentIdHashOnChange, this),
      this.history.on('commentIdRemove', this.commentIdHashOnRemove, this),

      this.containerEl.delegate('click', (function () {

        var cancelHandle,
        anonLoginHandle;

        return function (e) {
          SQUARESPACE_LOGIN.configure({
            redirectAfterLogin: false,
            reloadAfterLogin: false,
            allowAnonymous: this.settings.allowAnon,
            overlayOpacity: 0.5 });


          var postLogin = function () {

            this.onLoading();
            this.commentData.visibleComments = 0;
            this.retrieveComments({
              onSuccess: function (data) {
                this.fullDataRender(data);
              },
              page: 1,
              order: this.sortOrder },
            this);

            cancelHandle.detach();
            anonLoginHandle.detach();
          };



          anonLoginHandle = SQUARESPACE_LOGIN.once('guestLogin', postLogin, this);
        };

      })(), '.squarespace-comment-login', this),

      this.containerEl.delegate('focus', function () {
        this.setAttribute('data-edited', true);
      }, '.comment-input'),

      this.containerEl.delegate('blur', function () {
        if (this.get('value') === '') {
          this.setAttribute('data-edited', false);
        }
      }, '.comment-input'),

      this.containerEl.delegate('focus', function (e) {
        e.target.setAttribute('data-edited', true);
      }, '.new-comment-area textArea', this),

      this.containerEl.delegate('click', this.extendComments, '.view-more-btn', this),
      this.containerEl.delegate('click', this.fullViewOnClick, '.full-view-btn', this),
      this.containerEl.delegate('click', this.commentBtnOnClick,
      '.top-level-comment-area .comment-btn:not(.read-only)', this),
      this.containerEl.delegate('click', this.togglePreview, '.top-level-comment-area .preview-comment', this),

      this.containerEl.delegate('change', function (e) {
        this.sort(e.target.get('value'));
      }, '.hidden-ordering', this));


    },

    destroy: function () {

      this.events.forEach(function (e) {

        e.detach();

      });

    },


    /**
     Permission check method. Does not use the util-authenticated one becasue
     we don't want to bring in Immutable, etc.
     @method userHasAccessPermission
     @param permissions {[AccessPermission]}
     */
    userHasAccessPermission: function (permission) {
      if (!Y.Object.getValue(Static, ['SQUARESPACE_CONTEXT', 'accessPermissions'])) {
        return false;
      }
      return Y.Array.indexOf(Static.SQUARESPACE_CONTEXT.accessPermissions, permission) >= 0;
    },


    /**
     * Run a callback after the user logs in. This function
     * will cause this callback to be detached if the user cancels
     * the login dialog.
     * @method doAfterLogin
     * @param loginMgr
     * @param {Function} cb
     * @param {Object} scope
     */
    doAfterLogin: function (loginMgr, cb, scope) {
      var onCancel,
      onLogin,
      onGuestLogin;

      onLogin = loginMgr.once('login', function () {
        cb.call(this);
        onGuestLogin.detach();
        onCancel.detach();
      }, scope);

      onGuestLogin = loginMgr.once('guestLogin', function () {
        onLogin.detach();
        onCancel.detach();
      });

      onCancel = loginMgr.once('cancel', function () {
        onLogin.detach();
        onGuestLogin.detach();
      });
    },

    /**
     * Run a callback after the user logs in as a guest. This function
     * will cause this callback to be detached if the user cancels
     * the login dialog.
     * @method doAfterGuestLogin
     * @param loginMgr
     * @param {Function} cb
     * @param {Object} scope
     */
    doAfterGuestLogin: function (loginMgr, cb, scope) {
      var onCancel,
      onGuestLogin,
      onLogin;

      onGuestLogin = loginMgr.once('guestLogin', function () {
        cb.call(this);
        onLogin.detach();
        onCancel.detach();
      }, scope);

      onLogin = loginMgr.once('login', function () {
        onGuestLogin.detach();
        onCancel.detach();
      });

      onCancel = loginMgr.once('cancel', function () {
        onGuestLogin.detach();
        onLogin.detach();
      });
    },

    /**
     * @method fullViewOnClick
     */
    fullViewOnClick: function () {
      this.history.addValue('commentId', null);
    },

    /**
     * @method updateTopLevelButton
     */
    updateTopLevelButton: function () {
      var oldButton = Y.one('.top-level-comment-area .comment-btn'),
      dummyData = {
        newCommentAreaTop: {
          authenticatedAccount: SQUARESPACE_LOGIN.account === null ? undefined : SQUARESPACE_LOGIN.account },

        comments: [{}] },

      fragment = this.renderToFragment(dummyData),
      newBtn = fragment.one('.top-level-comment-area .comment-btn');

      if (oldButton) {
        oldButton.ancestor('.comment-btn-wrapper').append(newBtn);
        oldButton.remove(true);
      }
    },

    /**
     * @method updateReplyAreaButtons
     */
    updateReplyAreaButtons: function () {
      var dummyData = {
        comments: [{
          authenticatedAccount: SQUARESPACE_LOGIN.account }] },


      newButton = this.renderToFragment(dummyData).one('.reply-area-wrapper .comment-btn');

      if (newButton) {
        this.commentsEl.all('.reply-area-wrapper .comment-btn').each(function (n) {
          n.insert(newButton.cloneNode(true), 'after');
          n.remove(true);
        });
      }
    },

    /**
     * @method refreshCommentPostButtons
     */
    refreshCommentPostButtons: function () {
      this.updateTopLevelButton();
      this.updateReplyAreaButtons();
    },

    /**
     * Generate a comment preview node.
     * @method generatePreview
     * @param textArea { YUI Node } YUI Node (textarea) from which the comment data will be pulled.
     * @private
     */
    generatePreview: function (textArea) {
      // Given a textArea, returns a rendered node representing its contents
      // with the class 'comment-preview'
      var body = Y.Squarespace.Escaping.escapeForHtml(textArea.get('value')),
      json = {
        comments: [{}] },

      commentNode = null,
      result = null,
      comment = json.comments[0],
      topLevel = !textArea.ancestor('.reply-area-wrapper'),
      depth,
      target;

      if (!topLevel) {
        target = Y.one('.comment[data-commentId="' +
        textArea.ancestor('.reply-area-wrapper').getAttribute('data-commentId') + '"]');
        depth = target.hasAttribute('data-depth') ? parseInt(target.getAttribute('data-depth'), 10) + 1 : 1;
      } else {
        depth = 0;
      }

      comment.body = Sanitizer.sanitize(Y.Squarespace.Lang.markdownToHTML(body, true));
      comment.memberAccount = Y.clone(SQUARESPACE_LOGIN.account);
      comment.authorName = (SQUARESPACE_LOGIN.account ? SQUARESPACE_LOGIN.account.displayName :
      CookieCutter.get('displayName')) || t("Not Logged In");
      comment.addedOn = new Date().getTime();
      comment.depth = depth;
      result = this.renderToFragment(this.scatterData(json));

      commentNode = result.one('.comment');
      commentNode.addClass('comment-preview');

      // Add meta-information.
      if (topLevel) {
        commentNode.addClass('top-level');
      } else {
        commentNode.setAttribute('data-previewId', target.getAttribute('data-commentId'));
        commentNode.setAttribute('data-replyTo', target.getAttribute('data-commentId'));
      }

      return result;
    },



    /**
     * Click handler for the "Post Reply" buttons on each comment. Determines whether
     * to initiate the reply submission workflow.
     * @method replyBtnOnClick
     * @private
     */
    replyBtnOnClick: function (e) {
      var wrapper = e.target.ancestor('.reply-area-wrapper'),
      targetId = wrapper.getAttribute('data-commentId'),
      targetComment = Y.one('.comment[data-commentId="' + targetId + '"]'),
      textArea = wrapper.one('textArea'),
      textContent = textArea.get('value');

      if (textContent.trim() === '') {
        this.showEmptyText();
      } else {
        var comment = this.injectReply(textContent, targetComment);
        this.replyTo(wrapper.getAttribute('data-commentId'), textContent, comment, textArea);
      }
      e.stopImmediatePropagation();
    },

    /**
     * Click handler for "Preview" button on textareas. Shows a preview if the button
     * state is closed and hides the preview if the button state is open.
     * @method togglePreview
     * @private
     */
    togglePreview: function (e) {
      var wrapper = e.target.ancestor('.reply-area-wrapper'),
      textArea;

      if (wrapper) {
        textArea = wrapper.one('textArea');
      } else {
        textArea = e.target.ancestor('.top-level-comment-area').one('textArea');
      }

      if (textArea.get('value') !== '') {
        if (e.target.getAttribute('data-state') === 'open') {
          e.target.setAttribute('data-state', 'closed');
          this.hidePreview(textArea);
        } else {
          e.target.setAttribute('data-state', 'open');
          this.showPreview(textArea);
        }
      }
    },

    /**
     * Show a preview of a comment given a textArea with text.
     * @method showPreview
     * @param textArea { YUI Node } YUI Node of the textArea from whence to pull text.
     * @private
     */
    showPreview: function (textArea) {
      if (textArea) {
        var previewNode = this.generatePreview(textArea),
        commentNode = previewNode.one('.comment'),
        replyWrapper = textArea.ancestor('.reply-area-wrapper'),
        inputWrapper = textArea.ancestor('.input'),
        topLevel = !replyWrapper,
        animTextAreaClosed,
        animPreviewOpen,
        wrapper;

        commentNode.wrap('<div class="new comment-preview-wrapper closed"></div>');
        wrapper = commentNode.ancestor('.comment-preview-wrapper');

        if (!topLevel) {
          replyWrapper.insert(wrapper, 'after');
          wrapper.setAttribute('data-commentId', replyWrapper.getAttribute('data-commentId'));
        } else {
          wrapper.addClass('top-level');
          Y.one('.comment-list').prepend(wrapper);

        }
        textArea.ancestor('.new-comment-area').one('.preview-comment').set('innerHTML', this.localizedStrings.edit);
        inputWrapper.setStyle('height', inputWrapper.get('offsetHeight'));

        animTextAreaClosed = new Y.Anim({
          node: inputWrapper,
          duration: 0.3,
          easing: Y.Easing.easeOutStrong,
          to: {
            height: 0 } });


        animTextAreaClosed.on('start', function () {
          this._node.setStyle('overflow', 'hidden');
        });
        animTextAreaClosed.run();

        animPreviewOpen = new Y.Anim({
          node: wrapper,
          duration: 0.3,
          easing: Y.Easing.easeOutStrong,
          to: {
            height: commentNode.get('offsetHeight') + parseInt(commentNode.getComputedStyle('paddingBottom'), 10) +
            parseInt(commentNode.getComputedStyle('marginBottom'), 10) } });


        animPreviewOpen.on('end', function () {
          this._node.addClass('open');
        });
        animPreviewOpen.run();
      }
    },

    /**
     * Hide all previews (except the top-level) open on the page.
     * @method hideAllPreviews
     * @private
     */
    hideAllPreviews: function () {
      Y.all('.comment-preview-wrapper:not(.top-level)').each(function (n) {
        var animPreviewClosed = new Y.Anim({
          node: n,
          duration: 0.3,
          easing: Y.Easing.easeOutStrong,
          to: {
            height: 0 } });


        animPreviewClosed.on('end', function () {
          this._node.remove(true);
        });
        animPreviewClosed.run();
      }, this);

      Y.all('.preview-comment[data-state="open"]:not(.top-level-preview-btn)').each(function (n) {
        n.setAttribute('data-state', 'closed');
        n.set('innerHTML', this.localizedStrings.preview);
      }, this);
    },

    /**
     * Hide a text area's comment preview.
     * @method hidePreview
     * @param textArea { YUI Node } YUI Node for the text area of the preview to be hidden.
     * @private
     */
    hidePreview: function (textArea) {
      if (textArea) {
        var replyWrapper = textArea.ancestor('.reply-area-wrapper'),
        animTextAreaOpen,
        animPreviewClosed,
        previewNode,
        wrapper;
        if (replyWrapper) {
          previewNode = Y.one('.comment-preview[data-previewId="' + replyWrapper.getAttribute('data-commentId') + '"]');
        } else {
          previewNode = Y.one('.comment-preview.top-level');
        }

        textArea.ancestor('.new-comment-area').one('.preview-comment').set('innerHTML', this.localizedStrings.preview);

        animTextAreaOpen = new Y.Anim({
          node: textArea.ancestor('.input'),
          duration: 0.3,
          easing: Y.Easing.easeOutStrong,
          to: {
            height: textArea.get('offsetHeight') } });


        animTextAreaOpen.on('end', function () {
          this._node.setStyle('overflow', 'visible');
        });
        animTextAreaOpen.run();

        if (previewNode) {
          wrapper = previewNode.ancestor('.comment-preview-wrapper');

          animPreviewClosed = new Y.Anim({
            node: wrapper,
            duration: 0.3,
            easing: Y.Easing.easeOutStrong,
            to: {
              height: 0 } });


          animPreviewClosed.on('start', function () {
            this._node.removeClass('open');
            this._node.addClass('closed');
          });
          animPreviewClosed.on('end', function () {
            this._node.remove(true);
          });
          animPreviewClosed.run();
        }
      }
    },

    /**
     * Retrieve the comment template from the server. All callbacks run in
     * comment system's context.
     * @method getTemplateData
     * @param success { Function } Callback to be executed on success.
     * @param failure { Function } Callback to be executed on failure.
     * @private
     */
    getTemplateData: function (success, failure) {
      Y.Data.get({
        url: '/api/template/GetTemplateSchema',
        data: {
          type: BlockType.COMMENTS },

        success: function (data) {
          this._template = data.html;
          success.call(this);
        },
        failure: failure },
      this);
    },

    /**
     * Retrieves likes and flags on the current page's comments
     * by the current user and updates those comments visual.
     * @method updateLikedFlaggedState
     * @private
     */
    updateLikedFlaggedState: function () {
      if (this.settings.flagsAllowed || this.settings.likesAllowed) {

        this.commentData.getLikesAndFlags({
          success: function (data) {
            var commentList = Y.one('.comment-list');
            commentList.all('.comment').each(function (n) {
              var commentId = n.getAttribute('data-commentId');
              if (this.settings.flagsAllowed) {
                if (data.flagMap[commentId]) {
                  n.addClass('flagged');
                } else {
                  n.removeClass('flagged');
                }
              }
              if (this.settings.likesAllowed) {
                if (data.likeMap[commentId]) {
                  n.addClass('liked');
                } else {
                  n.removeClass('liked');
                }
              }
            }, this);

          } },
        this);
      }
    },

    /**
     * Set up initial comment data and such. Needs refactoring.
     * @method create
     */
    create: function () {
      if (this.userHasAccessPermission(AccessPermissions.CONFIG_ACTIVITY_COMMENTS)) {
        this.moderationMode = true;
      }
      this.getTemplateData(function () {
        var commentId = this.history.get('commentId');
        if (!Y.Lang.isUndefined(commentId)) {
          this.onLoading();
          this.retrieveThread({
            onSuccess: this.threadRender,
            onFailure: function (resp) {
              this.onLoading();
              this.retrieveComments({
                onSuccess: function (data) {

                  this.fullDataRender(data);
                },
                page: 1,
                order: this.sortOrder });

            },
            order: this.sortOrder,
            commentId: commentId });

        } else {

          this.onLoading();
          this.retrieveComments({
            onSuccess: function (data) {
              this.fullDataRender(data);
            },
            page: 1,
            order: this.sortOrder });

        }
      }, function () {

      });
    },

    /**
     * Ensures the comment header has the proper information
     * regarding the number of comments on the page.
     * @method updateCommentCounts
     * @private
     */
    updateCommentCounts: function () {
      var frag = this.renderToFragment({
        visibleComments: this.commentData.visibleComments,
        totalComments: this.commentData.totalComments,
        comments: [] });


      if (this.commentData.visibleComments === 0) {
        this.containerEl.addClass('empty');
      } else {
        this.containerEl.removeClass('empty');
      }

      Y.one('.comment-count').set('innerHTML', frag.one('.comment-count').get('innerHTML'));
    },

    /**
      Takes a string and a number of words, returns the string consisting of the first numWords words in
      it, followed by an ellipsis.
      @method truncateAt
      @param string {String} What to truncate
      @param numWords {Integer} The number of words to allow before truncating with an ellipse
      @return {String} The truncated string
    */
    truncateAt: function (string, numWords) {
      if (!string) {
        return '';
      }

      if (numWords === undefined) {
        return string;
      }

      var result = string.split(' ').slice(0, numWords).join(' ');
      return result + (result.length > numWords ? result.substr(result.length - 1) === '.' ? '..' : '…' : '');
    },

    formatAccountData: function (account) {
      return {
        userAvatarUrl: (account.avatarAssetUrl ?
        account.avatarAssetUrl : '/universal/images-v6/default-avatar.png') + '?format=50w',
        userHref: account.websiteUrl ? 'http://' + Y.Squarespace.Escaping.escapeForHtml(account.websiteUrl) : '',
        userHtml: account.websiteUrl || '',
        userName: Y.Escape.html(account.displayName || ''),
        userBio: this.truncateAt(Y.Squarespace.Escaping.escapeForHtml(account.bio, 60)) || '' };

    },

    /**
     * Constructs a YUI node representing the user info panel for a given user.
     * @method buildInfoPane
     * @param account { Object } AuthenticatedAccount object.
     * @return { YUI Node } YUI Node for the newly constructed user info panel.
     * @private
     */
    buildInfoPane: function (account) {

      var userData = this.formatAccountData(account);
      var result = Y.Node.create('<div class="squarespace-user-info"></div>');
      var userInfoHeaderEl = Y.Node.create('<div class="user-info-header"></div>');
      var avatarEl = Y.Node.create('<div class="avatar"><img src="' + userData.userAvatarUrl + '" /></div>');
      var userInfoInfoTempl = Y.Lang.sub('<div class="info"><div class="name">{userName}</div>' +
      '<a class="website" href="{userHref}">{userHtml}</a></div>', userData);
      var userInfoInfoEl = Y.Node.create(userInfoInfoTempl);
      var userInfoBodyEl = Y.Node.create('<div class="user-info-body"><p>' + userData.userBio + '</p></div>');
      var userSocialEl = Y.Node.create('<div class="user-social-opts">' +
      '<div class="social-leftcol"></div><div class="social-rightcol"></div>' +
      '<div class="clearfix"></div>' +
      '</div>');

      userInfoHeaderEl.append(avatarEl).
      append(userInfoInfoEl);

      result.append(userInfoHeaderEl).
      append(userInfoBodyEl).
      append(userSocialEl);

      return result;
    },

    /**
     * Build and show a full-screen, transparent overlay
     * to capture clicks.
     * @method showClickOverlay
     * @private
     */
    showClickOverlay: function () {
      var doc = Y.one(document),
      winHeight = doc.get('winHeight'),
      winWidth = doc.get('winWidth');

      this.overlayEl = Y.Node.create('<div class="user-profile-overlay"></div>');

      this.overlayEl.setStyles({
        'position': 'fixed',
        'background': '#000',
        'height': winHeight + 'px',
        'width': winWidth + 'px',
        'opacity': '0',
        'top': '0px',
        'left': '0px',
        'zIndex': 999 });


      Y.one(Y.config.doc.body).append(this.overlayEl);
      Y.on('click', this.hideUserProfileFlyout, this.overlayEl, this);
    },

    showUserProfileFlyout: function (e) {
      if (!e.target.hasClass('squarespace-comment-user-flyout') &&
      !e.target.ancestor('.squarespace-comment-user-flyout') &&
      !this.flyoutOpen) {

        var commentId = e.target.ancestor('.comment').getAttribute('data-commentid'),
        comment = this.commentData.getCommentById(commentId);

        // Grab user info
        var user = comment.memberAccount;

        if (!user) {
          return;
        }
        // Create flyout
        var flyoutNode = Y.Node.create('<div class="squarespace-comment-user-flyout"></div>');
        flyoutNode.append(this.buildInfoPane(user));
        flyoutNode.append('<div class="squarespace-user-activity">' +
        '<div class="squarespace-activity-header">' +
        '<div class="title">' + t("Activity") +

        '</div>' +
        '<div class="description">' + t("Comments and likes across the Squarespace platform") +

        '</div>' +
        '</div>' +
        '<div class="feed"></div>' +
        '</div>');
        flyoutNode.append('<div class="squarespace-comment-user-clearfix"></div>');

        var body = Y.one(document.body);
        body.append(flyoutNode);

        // Positioning
        flyoutNode.setStyles({
          position: 'fixed',
          left: '50%',
          top: '50%',
          opacity: 0,
          'zIndex': 1000,
          'transform': 'scale(.95)' });


        flyoutNode.setStyles({
          marginTop: -(parseInt(flyoutNode.get('offsetHeight'), 10) / 2),
          marginLeft: -(parseInt(flyoutNode.get('offsetWidth'), 10) / 2) });


        flyoutNode.transition({
          WebkitTransform: {
            duration: 0.3,
            easing: 'ease-out',
            value: 'scale(1)' },


          opacity: {
            duration: 0.3,
            easing: 'ease-out',
            value: 1 } });



      }
      this.flyoutOpen = true;
      this.showClickOverlay();
      e.stopImmediatePropagation();

    },

    hideUserProfileFlyout: function (e) {

      if (this.flyoutOpen) {

        var flyout = Y.one('.squarespace-comment-user-flyout');

        if (flyout) {

          flyout.transition({
            WebkitTransform: {
              duration: 0.3,
              easing: 'ease-out',
              value: 'scale(1.08)' },


            opacity: {
              duration: 0.4,
              easing: 'ease-out',
              value: 0 },


            on: {
              end: Y.bind(function () {
                flyout.remove(true);
                e.target.remove(true);
                this.flyoutOpen = false;
              }, this) } });


        }
      }
    },



    /**
     * Build an array containing a node's sub-nodes (DOM nodes, not in a comment graph, by thread)
     * @method getThreadNodes
     * @param node { YUI Node } The root of the thread.
     * @param acc { Array } Accumulator in which the nodes will be deposited.
     */
    getThreadNodes: function (node, acc) {
      var id = node.getAttribute('data-commentId');

      acc.push(node);
      Y.all('.comment[data-replyTo="' + id + '"]').each(function (n) {
        this.getThreadNodes(n, acc);
      }, this);

    },

    /**
     * Performs an actual delete. Only called from _delete.
     * @method actualDelete
     * @param e { Event } YUI event object passed from the click handler, _delete.
     * @param spam { Boolean } Mark these comments as spam?
     * @param thread { [YUI Node] } Array of YUI nodes representing the affected
     *    DOM nodes of the delete.
     * @private
     */
    actualDelete: function (e, spam, thread) {

      var comment = e.target.ancestor('.comment'),
      id = comment.getAttribute('data-commentId'),
      animWrapper,
      animDeletedOut,
      currSortTime = this.lastSortTime,
      currHashChangeTime = this.lastHashChangeTime,
      successFunc = function (resp) {

        // If the comment list has been sorted since this request was made,
        // we have invalidated our node references, so do nothing.
        if (currSortTime === this.lastSortTime && currHashChangeTime === this.lastHashChangeTime) {
          animWrapper = Y.Node.create('<div class="delete-anim-wrapper"></div>');
          comment.insert(animWrapper, 'after');

          // Collect all the thread's nodes into a single wraper. Order is preserved
          // and these are all guaranteed to be adjacent siblings in the DOM so this is
          // safe.
          thread.forEach(function (n) {
            animWrapper.append(n);
            var reply = Y.one('.reply-area-wrapper[data-commentId="' + n.getAttribute('data-commentId') + '"]');
            if (reply) {
              reply.remove(true);
            }
          });

          // Slide the affected thread out of view and remove it from the DOM.
          animDeletedOut = new Y.Anim({
            node: animWrapper,
            duration: 0.5,
            easing: Y.Easing.easeOutStrong,
            to: {
              height: 0 } });


          animDeletedOut.on('end', function () {
            animWrapper.remove(true);

            // If the comment originally deleted had children cascade-deleted,
            // make sure the comment data module knows. (We could defer this
            // to the data module, but we happen to have the information
            // already available here in the DOM, so no need to do the work twice.)
            if (thread.length > 1) {
              this.commentData.removeCommentsByIds(thread.map(function (com) {
                return com.getAttribute('data-commentId');
              }).filter(function (commentId) {return commentId !== id;}));
            }
            this.updateCommentCounts();
          }, this);
          animDeletedOut.run();
        }
      },
      failureFunc = function (resp) {
        if (resp.authorizationFail) {
          new Y.Squarespace.Widgets.Alert({
            'strings.title': t("Authorization Failure"),


            'strings.message': t("You are not allowed to perform that action.") });



        }
      };
      this.commentData._delete({
        comments: [id],
        spam: spam,
        success: successFunc,
        failure: failureFunc },
      this);
    },

    /**
     * Event handler for clicking the delete button on a comment.
     * Displays a confirmation dialog prompting further user delete action.
     * @method onDeleteClick
     * @private
     */
    onDeleteClick: function (e) {
      var confirmation,
      comment = e.target.ancestor('.comment'),
      buildThread = function () {
        var nodes = [];
        this.getThreadNodes(comment, nodes);
        return nodes;
      },
      thread = buildThread.call(this),
      fadeThreadIn = function () {
        thread.forEach(function (n) {
          if (!n.hasClass('unmoderated')) {
            var fadeInAnim = new Y.Anim({
              node: n,
              duration: 0.3,
              easing: Y.Easing.easeOutStrong,
              to: {
                opacity: 1 } });


            fadeInAnim.on('end', function () {
              this._node.removeClass('pending-delete');
            });
            fadeInAnim.run();
          } else {
            n.removeClass('pending-delete');
          }
        });
      },
      confirmationProceed = function () {
        fadeThreadIn();
        this.actualDelete(e, true, thread);
      },
      confirmationReject = function () {
        fadeThreadIn();
        this.actualDelete(e, false, thread);
      },
      confirmationCancel = function () {
        fadeThreadIn();
      };

      thread.forEach(function (n) {
        if (!n.hasClass('unmoderated')) {
          var halfFadeAnim = new Y.Anim({
            node: n,
            duration: 0.3,
            easing: Y.Easing.easeOutStrong,
            to: {
              opacity: 0.5 } });


          halfFadeAnim.on('end', function () {
            this._node.addClass('pending-delete');
          });
          halfFadeAnim.run();
        } else {
          n.addClass('pending-delete');
        }
      });
      confirmation = new Y.Squarespace.Widgets.Confirmation({
        style: Y.Squarespace.Widgets.Confirmation.TYPE.CONFIRM_OR_REJECT,
        'strings.reject': t("Delete"),


        'strings.confirm': t("Delete & Report Spam"),


        'strings.title': pluralize({ one: "Delete Comment", other: "Delete {%n} Comments" },


        thread.length),



        'strings.message': pluralize({ one: "Are you sure you wish to delete this comment?", other: "Are you sure you wish to delete these comments?" },


        thread.length) });





      confirmation.on('cancel', confirmationCancel, this);
      confirmation.on('reject', confirmationReject, this);
      confirmation.on('confirm', confirmationProceed, this);

    },

    /**
     * Click handler for the approve button on comments. Determines whether to initiate the
     * approve comment workflow.
     * @method onApproveClick
     * @private
     */
    onApproveClick: function (e) {
      var comment = e.target.ancestor('.comment');
      this.approve(comment.getAttribute('data-commentId'), comment);
      e.stopImmediatePropagation();
    },

    /**
     * Approve a comment.
     * @method approve
     * @param id { String } ObjectId of the comment to approve.
     * @param node { YUI Node } YUI node of the comment to mark as approved.
     * @private
     */
    approve: function (id, node) {
      var successFunc = function () {
        this.updateCommentCounts();
      },
      failureFunc = function (resp) {
        if (resp.error) {
          new Y.Squarespace.Widgets.Alert({
            'strings.title': t("Failed to Approve Comment"),


            'strings.message': resp.error });

        }
      };

      node.removeClass('unmoderated');
      this.commentData.approve({
        comments: [id],
        success: successFunc,
        failure: failureFunc },
      this);
    },

    /**
     * Click handler for the unlike button on a comment. Determines whether to initiate
     * the unlike comment workflow.
     * @method onUnlikeClick
     * @private
     */
    onUnlikeClick: function (e) {
      var comment = e.target.ancestor('.comment');
      this.unlike(comment.getAttribute('data-commentId'), comment);
      e.stopImmediatePropagation();
    },

    /**
     * Unlike a comment.
     * @method unlike
     * @param id { String } ObjectId of the comment to unlike.
     * @param node { YUI Node } YUI node of the comment to mark as unliked.
     * @private
     */
    unlike: function (id, node) {
      var likesNode = node.one('.likes'),
      successFunc = function () {
        if (likesNode) {
          var data = {
            comments: [{
              likesAllowed: {
                likeCount: this.commentData.getCommentById(id).likeCount } }] },



          result = this.renderToFragment(this.scatterData(data)).one('.likes');

          likesNode.replace(result);
        }
      },
      failureFunc = function (resp) {
        if (resp.error) {
          new Y.Squarespace.Widgets.Alert({
            'strings.title': t("Failed to Unlike Comment"),


            'strings.message': resp.error });

        }
      };


      node.removeClass('liked');
      this.commentData.unlike({
        id: id,
        success: successFunc,
        failure: failureFunc },
      this);
    },

    /**
     * Click handler for flag button. Determines whether to initiate the flag comment workflow.
     * @method onFlagClick
     * @private
     */
    onFlagClick: function (e) {
      var proceed = function () {
        var comment = e.target.ancestor('.comment');

        this.flag(comment.getAttribute('data-commentId'), comment);
      };

      new Y.Squarespace.Widgets.Confirmation({
        'strings.title': t("Flag Comment"),


        'strings.message': t("Are you sure you wish to flag this comment as inappropriate?") }).


      on('confirm', proceed, this);

      e.stopImmediatePropagation();
    },

    /**
     * Flag a comment as inappropriate.
     * @method flag
     * @param id { String } ObjectId of the comment to be flagged.
     * @param commentNode { YUI Node } Node of the comment to be marked as flagged.
     */
    flag: function (id, node) {
      var successFunc = function () {
      },
      failureFunc = function (resp) {
        node.removeClass('flagged');
        if (resp.error) {
          new Y.Squarespace.Widgets.Alert({
            'strings.title': t("Failed to Flag Comment"),


            'strings.message': resp.error });

        }
      };


      node.addClass('flagged');
      this.commentData.flag({
        id: id,
        success: successFunc,
        failure: failureFunc },
      this);

    },

    /**
     * Click handler for the unflag button. Determines whether to initiate the
     * unflag workflow.
     * @method onUnflagClick
     * @private
     */
    onUnflagClick: function (e) {
      var comment = e.target.ancestor('.comment');
      this.unflag(comment.getAttribute('data-commentId'), comment);
      e.stopImmediatePropagation();
    },

    /**
     * Unflag a comment.
     * @method unflag
     * @param id { String } ObjectId of the comment to unflag.
     * @param node { YUI Node } YUI node of the comment to mark as unflagged.
     */
    unflag: function (id, node) {

      var successFunc = function () {
      },
      failureFunc = function (resp) {
        node.addClass('flagged');
        if (resp.error) {
          new Y.Squarespace.Widgets.Alert({
            'strings.title': t("Failed to Un-flag Comment"),


            'strings.message': resp.error });

        }
      };
      node.removeClass('flagged');
      this.commentData.unflag({
        id: id,
        success: successFunc,
        failure: failureFunc },
      this);
    },

    /**
     * Click handler for the like button. Determines whether to initiate the like comment
     * workflow
     * @method onLikeClick
     * @private
     */
    onLikeClick: function (e) {
      var comment = e.target.ancestor('.comment');
      this.like(comment.getAttribute('data-commentId'), comment);
      e.stopImmediatePropagation();
    },

    /**
     * Like a comment.
     * @method like
     * @param id { String } ObjectId of the comment to like.
     * @param node { YUI Node } YUI node of the comment to mark as liked.
     * @private
     */
    like: function (id, node) {
      var likesNode = node.one('.likes'),
      commentId = node.getAttribute('data-commentid'),
      successFunc = function () {
        if (likesNode && !Y.Lang.isNull(node._node)) {

          var data = {
            comments: [{
              likesAllowed: {
                likeCount: this.commentData.getCommentById(commentId).likeCount } }] },



          result = this.renderToFragment(this.scatterData(data)).one('.likes');

          likesNode.replace(result);
        }
      },
      failureFunc = function (resp) {
        node.removeClass('liked');
        if (resp.error) {
          new Y.Squarespace.Widgets.Alert({
            'strings.title': t("Failed to Like Comment"),


            'strings.message': resp.error });

        }
      };

      node.addClass('liked');
      this.commentData.like({
        id: id,
        success: successFunc,
        failure: failureFunc },
      this);
    },

    addLoadMoreControls: function (fragment) {
      var previousNode = Y.one('.load-more');
      if (previousNode) {
        previousNode.remove(true);
      }
      // Append the "Load More Comments" button to the bottom of the comments
      if (!this.threadState && this.commentData.page < this.commentData.numPages) {
        this.containerEl.append(this.loadMoreNode.cloneNode(true));
      }
    },

    addFullViewControls: function (fragment) {
      var previousNode = Y.one('.full-view-btn');
      if (previousNode) {
        previousNode.remove(true);
      }

      if (this.threadState) {
        this.containerEl.one('.comment-list').insert(this.fullViewNode.cloneNode(true), 'before');
      }
    },

    onReplyToggleClick: function (e) {

      var comment = e.target.ancestor('.comment'),
      id = comment.getAttribute('data-commentid'),
      wrapper = Y.one('.reply-area-wrapper[data-commentid="' + id + '"]'),
      body = Y.one(Y.config.doc.body),
      animReplyClosed,
      animReplyOpen,
      close = Y.bind(function (wrap, onClose) {
        // Animate the reply area to closed.

        if (wrap) {

          // We set the height again here just in case it was unset by a reply opening.
          // Animating from height: auto to some value doesn't work.
          wrap.setStyles({
            'overflow': 'hidden',
            'height': wrapper.get('offsetHeight') });

          wrap.one('.input').setStyle('height', 'auto');
          Y.one('.comment[data-commentid="' + wrap.getAttribute('data-commentId') + '"]').fire('reply-close');

          animReplyClosed = new Y.Anim({
            node: wrapper,
            duration: 0.3,
            easing: Y.Easing.easeOutStrong,
            to: {
              height: 0 } });


          animReplyClosed.on('end', function () {
            // if(Y.Lang.isFunction(onClose)) {
            //   onClose.call(this._node);
            // }
          }, this);
          animReplyClosed.run();
        }
      }, this),
      open = Y.bind(function (wrap) {
        // Animate the reply area to open.

        if (wrap) {
          var wrapperH,
          proxy;

          proxy = wrap.one('.new-comment-area').cloneNode(true);
          proxy.setStyles({
            'visibility': 'hidden',
            'display': 'block',
            'position': 'absolute' });

          comment.append(proxy);
          wrapperH = proxy.get('offsetHeight');
          proxy.remove(true);

          Y.one('.comment[data-commentid="' + wrap.getAttribute('data-commentId') + '"]').fire('reply-open');

          this.hideAllPreviews();
          animReplyOpen = new Y.Anim({
            node: wrap,
            duration: 0.3,
            easing: Y.Easing.easeOutStrong,
            to: {
              height: Math.max(wrapperH, 190) + 15 } });


          animReplyOpen.on('end', function () {
            // Set height auto here so their when the child elements
            // resize (Happens when animating previews), it will resize as well.
            this._node.setStyle('height', 'auto');
            var dy = this._node.getY() + this._node.get('offsetHeight') - (
            body.get('docScrollY') + body.get('winHeight')),
            currScrollTop = body.get('scrollTop'),
            scrollWindowToVisible;
            if (dy >= 0) {
              scrollWindowToVisible = new Y.Anim({
                node: body,
                duration: 0.3,
                easing: Y.Easing.easeOutStrong,
                to: {
                  scrollTop: currScrollTop + dy //+ parseInt(comment.getComputedStyle('paddingBottom'), 10)
                } });

              scrollWindowToVisible.run();
            }
          });
          animReplyOpen.run();
        }
      }, this),
      isReplyOpen = function (com) {
        return com.hasClass('reply-open');
      };

      if (isReplyOpen(comment)) {

        comment.removeClass('reply-open');
        this.hideAllPreviews();
        close(wrapper);

      } else {
        // Bind data
        wrapper.one('.reply-area').setAttribute('replyid', id);

        // Close all other open reply areas.
        Y.all('.comment.reply-open').each(function (n) {

          // Trigger the close animation
          n.removeClass('reply-open');

          var replyAreaWrapper =
          Y.one('.reply-area-wrapper[data-commentid="' + n.getAttribute('data-commentid') + '"]');
          close(replyAreaWrapper);
        });

        var area = wrapper.one('textArea');

        // Listen for the reply complete event and close this reply area.
        if (!this.replyCompleteEvents[comment.getAttribute('data-commentId')]) {
          this.replyCompleteEvents[comment.getAttribute('data-commentId')] =
          this.once('reply-complete', function (data) {
            close(wrapper, function () {
              this.one('.input').setStyle('height', 'auto');
            });
            this.replyCompleteEvents[comment.getAttribute('data-commentId')] = null;
          }, this);
        }

        area.on('focus', function () {
          this.setAttribute('data-edited', true);
        });
        area.on('blur', function () {
          if (this.get('value') === '') {
            this.setAttribute('data-edited', false);
          }
        });

        // Trigger the open animation
        comment.addClass('reply-open');
        open(wrapper);
      }
      e.stopImmediatePropagation();
    },

    escapeComment: function (comment) {
      comment.authorName = Y.Squarespace.Escaping.escapeForHtml(comment.authorName);
      comment.authorUrl = Y.Squarespace.Escaping.escapeForHtml(comment.authorUrl);
      comment.authorEmail = Y.Squarespace.Escaping.escapeForHtml(comment.authorEmail);
      if (comment.memberAccount) {
        comment.memberAccount.displayName = Y.Squarespace.Escaping.escapeForHtml(comment.memberAccount.displayName);
        comment.memberAccount.bio = Y.Squarespace.Escaping.escapeForHtml(comment.memberAccount.bio);
      }
    },

    /**
     * Add a reply area to the provided comment node. Node must have an id and depth.
     * @method addReplyArea
     * @param commentNode { YUI Node } YUI Node of the comment for which a reply
     * area will be generated.
     * @private
     */
    addReplyArea: function (commentNode) {
      var existingWrapper = Y.one('.reply-area-wrapper'),
      wrapperClone,
      dummyCommentData,
      fragment,
      newWrapper;

      if (existingWrapper) {
        existingWrapper.one('.preview-comment').
        setAttribute('data-state', 'closed').
        set('innerHTML', this.localizedStrings.preview);
        wrapperClone = existingWrapper.cloneNode(true);
        wrapperClone.setAttribute('data-commentId', commentNode.getAttribute('data-commentId'));
        wrapperClone.setAttribute('data-depth', commentNode.getAttribute('data-depth'));
        newWrapper = wrapperClone;
      } else {
        dummyCommentData = {
          comments: [{
            id: commentNode.getAttribute('data-commentId'),
            depth: commentNode.getAttribute('data-depth') }] };



        fragment = this.renderToFragment(dummyCommentData);


        newWrapper = fragment.one('.reply-area-wrapper').cloneNode(true);
      }

      // It's possible this copied reply wrapper is being animated and has some
      // strange properties set. Make sure we reset all the crap.
      newWrapper.setStyle('height', null);
      commentNode.insert(newWrapper, 'after');
    },

    /**
     * Render a comment to the beginning of the comments list.
     * @method prependComment
     * @param text { String } Body text of the comment.
     * @private
     */
    prependComment: function (text) {
      var authorName = (SQUARESPACE_LOGIN.account ?
      SQUARESPACE_LOGIN.account.displayName : CookieCutter.get('displayName')) || t("Not Logged In"),


      textField = Y.one('.top-level-comment-area .comment-input'),
      previewNode = Y.one('.top-level.comment-preview'),
      previewGenerated = false,
      animPreviewIn,
      previewWrapper;

      if (!previewNode) {
        previewNode = this.generatePreview(textField).one('.comment-preview');
        previewGenerated = true;
        previewNode.wrap('<div class="comment-preview-wrapper"></div>');
        previewWrapper = previewNode.ancestor('.comment-preview-wrapper');
        Y.one('.comment-list').prepend(previewWrapper);
      } else {
        previewNode.one('.author').set('innerHTML', Y.Escape.html(authorName));
        previewWrapper = previewNode.ancestor('.comment-preview-wrapper');
      }

      previewNode.removeClass('comment-preview');
      previewNode.addClass('pending');

      Y.one('.top-level-comment-area .preview-comment').setAttribute('data-state', 'closed');

      this.hidePreview(textField);

      if (previewGenerated) {
        animPreviewIn = new Y.Anim({
          node: previewWrapper,
          duration: 0.3,
          easing: Y.Easing.easeOutStrong,
          to: {
            height: previewNode.get('offsetHeight') + parseInt(previewNode.getComputedStyle('marginBottom'), 10) } });


        animPreviewIn.on('end', function () {
          previewNode.unwrap();
        });
        animPreviewIn.run();
      } else {
        previewNode.unwrap();
      }
      this.containerEl.removeClass('empty');

      this.makeMostRecent(previewNode);
      return previewNode;
    },

    /**
     * Render a single comment just below another.
     * @method * @method
     * @param text { String } Body of the comment to render.
     * @param parentComment { YUI Node } YUI Node of the parent comment.
     *   Comment will be rendered under this one with its nesting
     * depth + 1.
     * @private
     */
    injectReply: function (text, parentComment) {
      var id = parentComment.getAttribute('data-commentId'),
      previewWrapper = Y.one('.comment-preview-wrapper[data-commentId="' + id + '"]'),
      textField = Y.one('.reply-area-wrapper[data-commentId="' + id + '"]').one('textArea'),
      authorName = (SQUARESPACE_LOGIN.account ?
      SQUARESPACE_LOGIN.account.displayName : CookieCutter.get('displayName')) || t("Not Logged In"),


      previewGenerated = false,
      previewNode;

      if (!previewWrapper) {
        previewNode = this.generatePreview(textField).one('.comment-preview');
        previewGenerated = true;
        previewNode.wrap('<div class="comment-preview-wrapper"></div>');
        previewWrapper = previewNode.ancestor('.comment-preivew-wrapper');
      } else {
        previewNode = previewWrapper.one('.comment-preview');
        previewNode.one('.author').set('innerHTML', Y.Escape.html(authorName));
      }


      if (parentComment.hasAttribute('data-depth')) {
        previewNode.setAttribute('data-depth', parseInt(parentComment.getAttribute('data-depth'), 10) + 1);
      }
      previewNode.unwrap();
      previewNode.removeClass('comment-preview');
      previewNode.addClass('pending');

      parentComment.removeClass('reply-open');
      parentComment.fire('reply-complete');

      if (previewGenerated) {
        textField.ancestor('.reply-area-wrapper').insert(previewNode, 'after');
      }
      this.makeMostRecent(previewNode);
      this.fire('reply-complete', { node: previewNode });
      return previewNode;
    },

    /**
     * Mark this node as the most recently posted one.
     * @method makeMostRecent
     * @param node { YUI Node } YUI Node to promote to 'most-recent' status.
     * @private
     */
    makeMostRecent: function (node) {
      if (node.hasClass('comment')) {
        Y.all('.comment.pending.most-recent').each(function (n) {
          if (!n.ancestor('.most-recent-anim-wrapper')) {
            n.removeClass('most-recent');
          }
        });
        node.addClass('most-recent');
      }
    },

    fullDataRender: function () {
      this.render(this.commentData.formatted);
      this.commentList = Y.one('.comment-list');
    },

    threadRender: function () {
      this.render(this.commentData.formattedThread);
      this.commentList = Y.one('.comment-list');
      this.commentList.one('.comment[data-commentId="' + this.commentData.currentThreadRoot + '"]').addClass('target');
    },

    /**
     * Shows an alert indicating that you must enter text to post a comment.
     * @method showEmptyText
     * @private
     */
    showEmptyText: function () {
      new Y.Squarespace.Widgets.Alert({
        'strings.title': t("Enter Comment Text"),


        'strings.message': t("Enter text in the field above to post a comment.") });



    },

    /**
     * Shows a notification confirming that a comment has been posted, but it pending moderation.
     * @method showModerationNotification
     * @private
     */
    showModerationNotification: function () {
      new Y.Squarespace.Widgets.Alert({
        className: 'okay',
        'strings.title': t("Comment Awaiting Moderation"),


        'strings.message': t("Your comment has been successfully posted and is awaiting moderation.") });







    },

    /**
     * Shows a notification confirming that a comment has been posted.
     * @method showCommentSuccessNotification
     * @private
     */
    showCommentSuccessNotification: function () {
      new Y.Squarespace.Widgets.Alert({
        className: 'okay',
        'strings.title': t("Comment Posted"),


        'strings.message': t("Your comment has been successfully posted.") });



    },

    /**
     * Shows a notification confirming that a comment has been posted or is pending moderation.
     * @method showPostCommentNotification
     * @private
     */
    showPostCommentNotification: function (comment) {

      // Only show notifications if the user posted in a moderation-required thread without trusted commenter rights.
      if (comment.status === CommentStatuses.AWAITING_MODERATION) {
        this.showModerationNotification();
      }
    },

    /**
     * Check whether participation in this comment thread is limited only to
     * global administrators.
     * @squarespaceAdminOnly
     */
    squarespaceAdminOnly: function () {

      // Block everyone but global administrators if this is a cloneable site and the
      // user is not logged in or if they are logged in, but lack global admin rights.
      //
      // This block is mirrored on the server as well, of course.
      return Static.SQUARESPACE_CONTEXT.website.cloneable &&
      !this.userHasAccessPermission(AccessPermissions.ADMIN);
    },


    /**
     * Click handler for top-level comment button. Determines whether to start
     * the comment submission workflow.
     * @method commentBtnOnClick
     * @private
     */
    commentBtnOnClick: function (e) {

      var inputField = Y.one('.top-level-comment-area .comment-input');
      var textContent = inputField.get('value');

      if (textContent.trim() === '') {
        this.showEmptyText();
      } else {
        var comment = this.prependComment(textContent);
        this.submitTopLevel(textContent, comment, inputField);
      }
      e.stopImmediatePropagation();
    },

    /**
    *
    * Update the member account information on a rendered comment to cover this special case due to XDR commenting:
    *
    * If we posted this comment on an insecure domain, but we were actually logged in,
    * this comment was posted as the logged in user. However, the client actually had no idea,
    * before this moment, who the logged in user was. Because we pre-render the comment for the
    * preview animation and the comment pending state (greyed out) with "Not Logged In" as the name,
    * we must update the fields that depend on the member account.
    *
    * @method updateWithMemberAccount
    * @param commentNode { Node } The comment node to update.
    * @param memberAccount { Object } The memberAccount object that this comment will respect after the update.
    * @private
    */
    updateWithMemberAccount: function (commentNode, memberAccount) {

      // This should not happen if the user is logged in,
      // thus the check for the login object's account.
      if (memberAccount && !SQUARESPACE_LOGIN.account) {
        var nameNode = commentNode.one('.author');
        if (nameNode) {
          nameNode.setContent(Y.Escape.html(memberAccount.displayName));
        }

        var avatarNode = commentNode.one('.avatar img');
        if (avatarNode) {
          if (memberAccount.avatarAssetUrl) {
            avatarNode.setAttribute('src', memberAccount.avatarAssetUrl);
          }
        }
      }
    },

    /**
     * Submit a top-level comment to the server.
     * @method submitTopLevel
     * @param text { String } The body of the comment.
     * @param comment { YUI Node } YUI Node representing the comment (preview)
     * @param textArea { YUI Node } YUI Node representing the text area from which this comment was generated.
     */
    submitTopLevel: function (text, comment, textArea) {

      if (!Y.Squarespace.Utils.areCookiesEnabled()) {
        new Y.Squarespace.Widgets.Alert({
          'strings.title': t("Comments disallowed"),


          'strings.message': t("This browser does not accept cookies, which is a requirement for commenting. You can allow cookies by changing your browser's settings.") });








        return;
      }

      // Toggle the indicator that this comment is posting
      // to visible, if the template specifies one.
      this.setPosting(comment, !this.approvalRequired || !this.moderationMode);
      var cachedTextContent = textArea.get('value');
      textArea.set('value', '');
      this.addComment({
        onSuccess: function (resp) {
          this.updateCommentCounts();

          // We have no guarantees these nodes still exist when this
          // callback is fired. If the user has re-sorted before the
          // server returns, we will get errors without these checks.
          if (!Y.Lang.isNull(comment._node)) {
            this.setPosted(comment);
            comment.setAttribute('data-commentId', resp.comment.id);
            if (resp.comment.status === CommentStatuses.APPROVED) {
              comment.removeClass('pending');
            }
            this.addReplyArea(comment);
            this.updateWithMemberAccount(comment, resp.comment.memberAccount);
            this.showPostCommentNotification(resp.comment);

          }
        },
        onFailure: function (resp) {
          textArea.set('value', cachedTextContent);
          if (resp.errors) {
            if (resp.errors.anonEmail || resp.errors.authorEmail || resp.errors.authorName) {
              var nameNode = comment.one('.author');

              if (resp.errors.anonEmail) {
                new Y.Squarespace.Widgets.Alert({
                  className: 'okay',
                  'strings.title': t("Comment Failed"),


                  'strings.message': resp.errors.anonEmail });

              }

              var cancelHandle = SQUARESPACE_LOGIN.once('cancel', this.removeMostRecentPending, this);

              var onGuestLogin = function () {
                this.submitTopLevel(text, comment, textArea);

                var displayName = CookieCutter.get('displayName');
                // Ensure the nodes are actually still there...
                if (displayName && !Y.Lang.isNull(nameNode) && !Y.Lang.isNull(nameNode._node)) {
                  nameNode.set('innerHTML', Y.Escape.html(displayName));
                }
                cancelHandle.detach();
              };

              this.doAfterGuestLogin(SQUARESPACE_LOGIN, onGuestLogin, this);

              SQUARESPACE_LOGIN.showCommentLogin();
            } else {
              this.showPostCommentNotification(resp.comment);
            }
          }
        },
        body: text },
      this);

    },

    /**
     * Animates away the most recent pending comment. Used to revert the UI
     * to the comment-editing workflow if a user cancels the request
     * submission step.
     * @method removeMostRecentPending
     * @private
     */
    removeMostRecentPending: function () {
      var mostRecent = Y.one('.comment.most-recent'),
      animMostRecentOut,
      animWrapper;

      if (mostRecent.ancestor('.comment-preview-wrapper')) {
        mostRecent.unwrap();
      }
      mostRecent.wrap('<div class="most-recent-anim-wrapper"></div>');
      animWrapper = mostRecent.ancestor('.most-recent-anim-wrapper');

      animWrapper.setStyles({
        'overflow': 'hidden',
        'height': mostRecent.get('offsetHeight') });


      animMostRecentOut = new Y.Anim({
        node: animWrapper,
        duration: 0.3,
        easing: Y.Easing.easeOutStrong,
        to: {
          height: 0 } });


      animMostRecentOut.on('end', function () {
        this._node.remove(true);
      });
      animMostRecentOut.run();
    },

    onLoading: function () {
      var commentsEl = Y.one('.comments-content'),
      overlay = Y.Node.create('<div class="comment-event-capture"></div>'),
      fadeCommentsList;

      overlay.setStyles({
        'position': 'absolute',
        'top': commentsEl.getY(),
        'left': commentsEl.getX(),
        'width': commentsEl.get('offsetWidth'),
        'height': commentsEl.get('offsetHeight'),
        'zIndex': 1000 });


      overlay.on('hover', function (e) {
        e.stopImmediatePropagation();
      });
      overlay.on('click', function (e) {
        e.stopImmediatePropagation();
      });
      Y.one(Y.config.doc.body).append(overlay);

      fadeCommentsList = new Y.Anim({
        node: commentsEl,
        duration: 0.3,
        easing: Y.Easing.easeOutStrong,
        to: {
          opacity: 0.3 } });


      fadeCommentsList.run();
    },

    /**
     * Callback for URL hash change events from the commentId property.
     * @method commentIdHashOnChange
     * @private
     */
    commentIdHashOnChange: function (e) {

      // This hash change is triggered by removals (set to null/undefined)
      // as well as adds. Just let the event pass through on removes.
      if (!(Y.Lang.isNull(e.newVal) || Y.Lang.isUndefined(e.newVal))) {
        this.threadState = true;
        this.lastHashChangeTime = new Date();
        this.onLoading();
        this.retrieveThread({
          commentId: e.newVal,
          order: this.sortOrder,
          onSuccess: function () {

            this.threadRender();
          } });

      }
    },

    /**
     * Callback for URL hash remove events from the commentId property.
     * @method commentIdHashOnRemove
     * @private
     */
    commentIdHashOnRemove: function () {
      this.threadState = false;
      this.lastHashChangeTime = new Date();
      this.onLoading();
      this.retrieveComments({
        onSuccess: function (data) {
          this.fullDataRender(data);
        },
        page: 1,
        order: this.sortOrder },
      this);
    },

    retrieveThread: function (options, context) {
      this.threadState = true;
      var wrappedFailure = function (resp) {
        this.threadState = false;
        options.onFailure.call(context || this, resp);
      };
      this.commentData.getThread({
        order: options.order,
        commentId: options.commentId,
        success: options.onSuccess,
        failure: wrappedFailure },
      context || this);
    },

    retrieveComments: function (options, context) {
      // A facade to allow sending options as params

      var wrappedSuccess = Y.bind(function (data) {
        this.lastPollTime = new Date();
        options.onSuccess.call(context || this, data);
        this.updateLikedFlaggedState();
      }, this);

      this.commentData.get({
        order: options.order,
        page: options.page,
        since: options.since || '',
        success: wrappedSuccess,
        failure: options.onFailure },
      this);

    },

    /**
     * Post a comment to the server.
     * @method addComment
     * @param options { Object } Config options:
     * @param context { Object } Object to be used as 'this' when callbacks are executed.
     * @private
     */
    addComment: function (options, context) {
      var wrappedSuccess = Y.bind(function (data) {
        // Wrap the success functor to do a bit of bookkeeping

        // Execute callback in the supplied context
        if (Y.Lang.isFunction(options.onSuccess)) {
          (options || noop).onSuccess.call(context || this, data);
        }
      }, this);

      var postData = {
        body: options.body,
        replyTo: options.replyTo,
        success: wrappedSuccess,
        failure: options.onFailure };

      postData.authorName = SQUARESPACE_LOGIN.account ?
      SQUARESPACE_LOGIN.account.displayName :
      CookieCutter.get('displayName') || '';
      postData.email = SQUARESPACE_LOGIN.account ?
      SQUARESPACE_LOGIN.account.email :
      CookieCutter.get('email') || '';
      postData.authorUrl = SQUARESPACE_LOGIN.account ?
      SQUARESPACE_LOGIN.account.websiteUrl :
      CookieCutter.get('websiteUrl') || '';
      this.commentData.post(postData, context || this);
    },

    /**
     * Set a comment (node) to its posting state.
     * @method setPosting
     * @param comment { YUI Node } YUI Node of the comment.
     */
    setPosting: function (comment, moderated) {
      comment.all('.posting-text').each(function (n) {
        n.removeClass('visible');
      });

      var msg = comment.one('.posting-text' + (moderated ? '.moderated' : ''));
      if (msg) {
        msg.addClass('visible');
      }
    },

    /**
     * Set a comment (node) to its posted state.
     * @method setPosted
     * @param comment { YUI Node } YUI Node of the comment.
     */
    setPosted: function (comment) {
      var msgs = comment.all('.posting-text');
      if (msgs) {
        msgs.each(function (n) {
          n.removeClass('visible');
        });
      }
    },

    /**
     * Reply to a comment.
     * @method replyTo
     * @param id { String } ObjectId of the comment to reply to.
     * @param text { String } Body of the comment.
     * @private
     */
    replyTo: function (id, text, comment, textField) {
      this.setPosting(comment, !this.approvalRequired || !this.moderationMode);
      var cachedTextContent = textField.get('value');
      textField.set('value', '');
      this.addComment({
        body: text,
        replyTo: id,
        onSuccess: function (resp) {
          this.updateCommentCounts();

          // We have no guarantees these nodes still exist when this
          // callback is fired. If the user has re-sorted before the
          // server returns, we will get errors without these checks.

          if (!Y.Lang.isNull(textField._node)) {
            this.setPosted(comment);
            comment.setAttribute('data-commentId', resp.comment.id);
            if (resp.comment.status === CommentStatuses.APPROVED) {
              comment.removeClass('pending');
            }
            this.addReplyArea(comment);
            this.updateWithMemberAccount(comment, resp.comment.memberAccount);
            this.showPostCommentNotification(resp.comment);
          }
        },
        onFailure: function (resp) {
          textField.set('value', cachedTextContent);
          if (resp.errors) {
            if (resp.errors.anonEmail || resp.errors.authorEmail || resp.errors.authorName) {
              var onLogin,
              onGuestLogin;

              if (resp.errors.anonEmail) {
                new Y.Squarespace.Widgets.Alert({
                  className: 'okay',
                  'strings.title': t("Comment Failed"),


                  'strings.message': resp.errors.anonEmail });

              }

              var cancelHandle = SQUARESPACE_LOGIN.once('cancel', this.removeMostRecentPending, this);

              onLogin = function () {
                this.replyTo(id, text, comment, textField);
                Y.one('.comment[data-replyTo="' + id + '"] .author').
                set('innerHTML', Y.Escape.html(SQUARESPACE_LOGIN.account.displayName));
                comment.one('.author').set('innerHTML', Y.Escape.html(SQUARESPACE_LOGIN.account.displayName));
                if (SQUARESPACE_LOGIN.account.avatarAssetUrl) {
                  var avatar = comment.one('.avatar');
                  avatar.one('img').setAttribute('src', SQUARESPACE_LOGIN.account.avatarAssetUrl);
                }
                cancelHandle.detach();
              };

              onGuestLogin = function () {
                this.replyTo(id, text, comment, textField);

                var displayName = CookieCutter.get('displayName');
                if (!Y.Lang.isNull(displayName)) {
                  Y.one('.comment[data-replyTo="' + id + '"] .author').set('innerHTML', Y.Escape.html(displayName));
                }
                cancelHandle.detach();
              };


              SQUARESPACE_LOGIN.configure({
                redirectAfterLogin: false,
                reloadAfterLogin: false,
                overlayOpacity: 0.5 });


              this.doAfterLogin(SQUARESPACE_LOGIN, onLogin, this);
              this.doAfterGuestLogin(SQUARESPACE_LOGIN, onGuestLogin, this);

              SQUARESPACE_LOGIN.showCommentLogin();
            }
          }
        } },
      this);
    },

    /**
     * Retrieve the next page of comments from the server.
     * @method extendComments
     * @private
     */
    extendComments: function () {

      this.currentPage = this.currentPage + 1;
      var currSortTime = this.lastSortTime,
      currHashChangeTime = this.lastHashChangeTime;
      this.retrieveComments({
        order: this.sortOrder,
        onSuccess: function () {
          if (currSortTime === this.lastSortTime && currHashChangeTime === this.lastHashChangeTime) {
            this.attachComments.call(this);
          }
        },
        page: this.currentPage },
      this);
    },

    /**
     * Renders the newest page of comments and appends them to the comment list.
     * @method attachComments
     * @private
     */
    attachComments: function () {
      // Render the comments and slap them onto the end of the list

      var data = this.commentData.formatted;
      data.comments = this.commentData.getFormattedPage(this.currentPage);

      var fragment = this.renderToFragment(data);

      this.updateCommentCounts();


      var commentList = Y.one('.comment-list');
      fragment.all('.comment, .reply-area-wrapper').each(function (n) {
        commentList.append(n);
      });

      this.containerEl.one('.load-more').remove(true);
      this.addLoadMoreControls();
    },

    sort: function (ordering) {
      // Sort comment list based on the supplied ordering

      var realOrdering = this.sortOrderLookup[ordering];
      CookieCutter.set('commentSortOrder', ordering);
      this.sortOrder = ordering;
      this.currentPage = 1;

      var hookThenRender = function (data) {
        this.lastSortTime = new Date();
        this.fullDataRender(data);

        var commentSortArea = Y.one('.comment-sort');
        commentSortArea.one('span').set('innerHTML', realOrdering);
        commentSortArea.one('select').set('value', this.sortOrder);
      };

      this.onLoading();
      // Make request for new comments in a certain order
      this.retrieveComments({
        order: this.sortOrder,
        onSuccess: hookThenRender,
        page: this.currentPage });


    },


    formatData: function (comments, likeMap, flagMap) {
      var id,
      result = {
        comments: [] },

      comment;
      likeMap = likeMap || {};
      flagMap = flagMap || {};

      var readOnlyState = this.squarespaceAdminOnly() || this.settings.readOnly;

      result.noAvatars = this.settings.avatarsHidden;
      result.likesAllowed = this.settings.likesAllowed;
      result.newCommentAreaTop = { authenticatedAccount: SQUARESPACE_LOGIN.account, readOnly: readOnlyState };
      result.commentSortTitle = this.sortOrderLookup[this.sortOrder];
      result.sortOldestFirst = this.sortOrder === CommentSortTypes.OLDEST_FIRST;
      result.sortNewestFirst = this.sortOrder === CommentSortTypes.NEWEST_FIRST;
      result.sortMostLiked = this.sortOrder === CommentSortTypes.MOST_LIKED;
      result.sortLeastLiked = this.sortOrder === CommentSortTypes.LEAST_LIKED;

      result.totalComments = this.commentData.totalComments;
      result.visibleComments = this.commentData.visibleComments;

      for (var i = 0; i < comments.length; i++) {
        comment = comments[i];

        comment.unmoderated = comment.status === CommentStatuses.AWAITING_MODERATION;
        comment.moderationMode = this.moderationMode;
        comment.readOnly = readOnlyState;
        comment.flagsAllowed = this.settings.flagsAllowed;
        if (comment.memberAccount) {
          comment.authorName = comment.memberAccount.displayName;
          comment.authorUrl = comment.memberAccount.websiteUrl;
        }

        if (comment.authorUrl && !/^https?:\/\//.test(comment.authorUrl)) {
          comment.authorUrl = 'http://' + comment.authorUrl;
        }

        id = comment.id;
        if (this.settings.likesAllowed) {
          comment.likesAllowed = { likes: pluralize({ one: "{%n} Like", other: "{%n} Likes" },


            comment.likeCount) };



          if (likeMap[id]) {
            comment.likesAllowed.liked = true;
          }
        }

        if (flagMap[id]) {
          comment.flagged = true;
        }

        comment.threaded = this.settings.threaded;
        result.comments.push(comment);
      }
      return result;
    },

    scatterData: function (data) {

      // Given data that's of the form:
      // {
      //    likemap: { ... },
      //    settings: { ... },
      //    comments: [
      //     { ... }
      //        .
      //        .
      //        .
      //    ]
      // }
      //
      // Transform it by zipping the comments together with the likeMap
      // (For a likemap entry with key k, add a field to the comment
      // with id k called 'liked' with any truthy value.), scattering
      // the comment settings (For each comment, add fields for settings
      // data that affects presentation like likesAllowed or threaded.)
      //
      // This will allow template developers to hook into comment settings
      // using conditional sections in JSONT.

      var readOnlyState = this.squarespaceAdminOnly() || this.settings.readOnly;
      data.noAvatars = this.settings.avatarsHidden;
      data.likesAllowed = this.settings.likesAllowed;
      data.newCommentAreaTop = {};
      data.newCommentAreaTop.readOnly = readOnlyState;
      data.newCommentAreaTop.authenticatedAccount = SQUARESPACE_LOGIN.account;
      data.commentSortTitle = this.sortOrderLookup[this.sortOrder];

      if (this.commentData.totalComments === 0) {
        data.noComments = true;
      }

      var comment = {};
      for (var i = 0; i < data.comments.length; i++) {
        comment = data.comments[i];

        comment.flagsAllowed = this.settings.flagsAllowed;
        comment.moderationMode = this.moderationMode;
        if (comment.memberAccount) {
          comment.authorName = comment.memberAccount.displayName;
          comment.authorUrl = comment.memberAccount.websiteUrl;
        }

        if (comment.authorUrl) {
          if (!(comment.authorUrl.startsWith('http://') || comment.authorUrl.startsWith('https://'))) {
            comment.authorUrl = 'http://' + comment.authorUrl;
          }
        }
        comment.readOnly = readOnlyState;

        if (this.settings.likesAllowed) {
          if (!comment.likesAllowed) {
            comment.likeCount = 0;
            comment.likesAllowed = {
              likeCount: 0 };

          }
          if (comment.likesAllowed.likeCount === 0) {
            comment.likeCount = 0;
          } else {
            comment.likeCount = comment.likesAllowed.likeCount;
            comment.likesAllowed = {
              likes: pluralize({ one: "{%n} Like", other: "{%n} Likes" },


              comment.likeCount) };




          }
        }

        comment.threaded = this.settings.threaded;

        if (data.likeMap && data.likeMap[comment.id] && comment.likesAllowed !== undefined) {
          comment.likesAllowed.liked = true;
        }

        if (data.flagMap && data.flagMap[comment.id]) {
          comment.flagged = true;
        }

        data.comments[i] = comment;
      }

      return data;

    },

    render: function (data) {
      // Render the supplied comment data.
      var fragment = this.renderToFragment(data),
      commentsEl = this.commentsEl,
      unFadeCommentsList,
      eventCapture = Y.one('.comment-event-capture');

      if (!data.comments.length) {
        this.containerEl.addClass('empty');
      } else {
        this.containerEl.removeClass('empty');
      }
      commentsEl.empty();
      commentsEl.append(fragment);

      unFadeCommentsList = new Y.Anim({
        node: commentsEl,
        duration: 0.3,
        easing: Y.Easing.easeOutStrong,
        to: {
          opacity: 1 } });


      unFadeCommentsList.on('end', function () {
        eventCapture.remove(true);
      });
      unFadeCommentsList.run();

      this.addFullViewControls();
      switch (this.params.paginationMode) {
        case this.PaginationModes.PAGE:
          //this.buildPaginationControls();
          break;

        case this.PaginationModes.EXTEND:

          this.addLoadMoreControls();
          break;

        default:
          break;}


      this.fire('render');
    },

    renderToFragment: function (data) {
      // Renders a data object to a document fragment and returns it.
      for (var i = 0; i < data.comments.length; i++) {
        this.escapeComment(data.comments[i]);
        data.comments[i].body = Sanitizer.sanitize(data.comments[i].body);
      }

      data.localizedStrings = this.localizedStrings;
      data.commentAnonAllowed = this.settings.allowAnon;

      var fragment = Y.Node.create(evaluateJsonTemplate(this._template, data)),
      comments = fragment.all('.comment');

      comments.each(function (n, index) {
        n.setAttribute('data-commentId', data.comments[index].id);
      });
      return fragment;
    } });



  Y.augment(Y.Squarespace.Comments, Y.EventTarget);

  var CommentsInstance = null;

  Y.config.win.Squarespace.onInitialize(Y, function () {

    var Static = Y.config.win.Static;

    // use disqus comments instead of Squarespace?
    var disqusShortname = Static.SQUARESPACE_CONTEXT.websiteSettings.disqusShortname;
    var commentsDiv;
    if (disqusShortname) {

      commentsDiv = Y.one('.squarespace-comments');

      if (commentsDiv && disqusShortname && disqusShortname !== '') {
        window.disqus_shortname = disqusShortname; // eslint-disable-line camelcase
        var currentId = commentsDiv.getAttribute('id');
        var disqusRequiredId = 'disqus_thread';

        // If the comment div doesn't have the id required by disqus to load,
        // set it.
        if (currentId !== disqusRequiredId) {
          commentsDiv.setAttribute('id', disqusRequiredId);
        }

        Y.Get.script('//' + disqusShortname + '.disqus.com/embed.js', {
          win: Y.config.win });

      }

      return; // don't continue loading

    }

    // If we don't find a 'squarespace-comments' element in which to insert markup, bail out.
    commentsDiv = Y.one('.squarespace-comments');
    var objectId,
    objectType,
    config = Static.SQUARESPACE_CONTEXT.websiteSettings;

    if (commentsDiv && !config.disqusShortname) {

      var itemId = commentsDiv.getAttribute('data-item-id');
      var collectionId = commentsDiv.getAttribute('data-collection-id');
      var commentState = parseInt(commentsDiv.getAttribute('data-comment-state'), 10);
      var publicCommentCount = parseInt(commentsDiv.getAttribute('data-public-comment-count'), 10);

      objectId = itemId || collectionId;

      if (objectId) {

        // The type of said element (Could be useful to later determine different behavior based on
        // context)
        objectType = itemId ? CommentTargetTypes.ITEM : CommentTargetTypes.COLLECTION;

        var disabled = false,
        readOnly = false;

        // If comments are disabled, but there were comments to show, initialize commenting in read-only mode.
        // If comments are disabled, and there are no comments on them, do not render at all.
        if (objectType === CommentTargetTypes.ITEM) {
          if (commentState === CommentStates.DISABLED) {
            if (publicCommentCount === 0) {
              disabled = true;
            } else {
              readOnly = true;
            }
          }
        }

        if (!disabled) {

          var initComments = function () {
            Y.use('squarespace-comments', function (Y) {// eslint-disable-line no-shadow

              CommentsInstance = new Y.Squarespace.Comments({
                containerEl: commentsDiv, // The element into which we inject comments.
                identifier: objectId, // The unique identifier of the commentable object.
                type: objectType, // The type of the commentable object.
                publicCommentCount: publicCommentCount,
                likesAllowed: config.commentLikesAllowed,
                flagsAllowed: config.commentFlagsAllowed,
                threaded: config.commentThreaded,
                approvalRequired: config.commentApprovalRequired,
                allowAnon: config.commentAnonAllowed,
                avatarsOn: config.commentAvatarsOn,
                readOnly: readOnly,
                sortType: CookieCutter.get('commentSortOrder') || config.commentSortType });


              CommentsInstance.create();
            });
          };
          initComments();
        }
      }
    }
  });

  Y.config.win.Squarespace.onDestroy(Y, function () {
    if (CommentsInstance) {
      CommentsInstance.destroy();
      CommentsInstance = null;
    }
  });
}, '1.0', {
  // IT IS EXCEPTIONALLY BAD THAT COMMENTS DEPENDS ON LOGIN, and DIALOG!
  requires: [
  'anim',
  'event',
  'history',
  'node',
  'selector-css3',
  'squarespace-comment-data',
  'squarespace-dialog',
  'squarespace-dialog-description',
  'squarespace-escaping-utils',
  'squarespace-login',
  'squarespace-markdown',
  'squarespace-ui-base',
  'squarespace-util',
  'squarespace-widgets-alert',
  'squarespace-widgets-confirmation',
  'squarespace-widgets-information',
  'transition'] });