/**
 * Do not use for new components. Stick it on the Y.Squarespace.Singletons
 * namespace instead.
 *
 * This can be imported by various modules.
 *
 * This is made global as window.Singleton in common.js for old templates like
 * bedford-framework to reference.
 *
 * @deprecated
 */
const Singleton = {
  create: function (protoDef) {
    if (__DEV__) {
      console.warn('[DEPRECATED] something uses common/Singleton', protoDef);
    }

    const C = function () {};
    C.prototype = protoDef;
    const r = new C();
    if (r.ready) {
      r.ready();
    }
    return (r);
  }
};
export default Singleton;
