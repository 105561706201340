import { t } from 'shared/i18n';
import substituteString from 'shared/utils/formatting/substituteString';
import DomainTypes from '@sqs/enums/DomainTypes';
import DomainProviderId from '@sqs/enums/DomainProviderId';
import DomainConnectionStrategyId from '@sqs/enums/DomainConnectionStrategyId';
import DomainsAPI from 'shared/utils/domains/apis/DomainsAPI';
import DomainConnectAPI from 'shared/utils/domains/apis/DomainConnectAPI';
import axios from '@sqs/network';

/**
 * Google domains related utility functions
 *
 * @module squarespace-google-domains-utils
 */
YUI.add('squarespace-google-domains-utils', function (Y) {

  Y.namespace('Squarespace.GoogleDomains');

  var showError = function (message) {
    if (!Y.Lang.isString(message)) {
      message = '';
    }
    var lb = new Y.Squarespace.Lightbox({
      disableNormalClose: true,
      clickAnywhereToExit: false,
      content:
      '<div class="bigtext">' +
      '<div class="title">' + t("Failed to add domain") +



      '</div>' +
      '<div class="description">' +
      message +
      '</div><div class="buttons"></div>' +
      '</div>',
      margin: 100,
      noHeightConstrain: true });


    lb.show();
  };

  /**
   * Some initialization steps related to signing up from our
   * google domains partnership
   *
   * @method _googleDomainsInit
   */
  Y.Squarespace.GoogleDomains.initialize = function () {
    var params = Y.Squarespace.GoogleDomains.getGoogleDomainUrlParams();
    if (Y.Lang.isNull(params.userId) || Y.Lang.isNull(params.domainName)) {
      // No google domains param, just return
      return;
    }
    // Save it for 7 days ...
    Y.Squarespace.SessionStorage.saveSessionData('googleDomain', params, new Date().addDays(7));
  };

  /**
   * Parse out specific google domain signup url param that they embed in
   * the url when they link to us.
   *
   * @method _googleDomainsInit
   */
  Y.Squarespace.GoogleDomains.getGoogleDomainUrlParams = function () {
    var urlParams = Y.QueryString.parse(window.location.search.substr(1));
    return {
      userId: urlParams.userId || null,
      domainName: urlParams.domainName || null };

  };

  /**
   * Retrieves stored google domains data from local storage
   */
  Y.Squarespace.GoogleDomains.getGoogleDomainSessionData = function () {
    return Y.Squarespace.SessionStorage.getSessionData('googleDomain');
  };

  Y.Squarespace.GoogleDomains.isGoogleDomainFlow = function () {
    var googleDomainData = this.getGoogleDomainSessionData();
    if (Y.Lang.isObject(googleDomainData)) {
      return true;
    }
    googleDomainData = Y.Squarespace.GoogleDomains.getGoogleDomainUrlParams();
    if (!Y.Lang.isNull(googleDomainData.userId) && !Y.Lang.isNull(googleDomainData.domainName)) {
      return true;
    }
    return false;
  };

  /**
   * Called from HTML file
   */
  Y.Squarespace.GoogleDomains.showConfirmMessage = function (websiteId, domainName, confirmCallback) {
    var googleDomainParams = Y.Squarespace.GoogleDomains.getGoogleDomainUrlParams();
    var userId = googleDomainParams.userId;
    var url = 'http://' + window.location.hostname;
    var lb = new Y.Squarespace.Lightbox({
      disableNormalClose: true,
      clickAnywhereToExit: false,
      content: substituteString(
      '<div class="bigtext">' +
      '<div class="title">' + t("Connect Domain: {domainName}") +

      '</div>' +
      '<div class="description">' + t("Your site <b>{url}</b> will be available at <b>http://www.{domainName}</b>.") +





      '</div>' +
      '<div class="buttons">' +
      '<input type="button" class="login-button" name="login" value="' + t("Connect Domain") +

      '"/>' +
      '</div>' +
      '</div>', {
        domainName: domainName,
        url: url }),

      noHeightConstrain: true });


    lb.show();

    lb.getContentEl().on('click', function () {
      lb.hide();
      confirmCallback(websiteId, userId, domainName);
    });
  };

  /**
   * Called form HTML file
   */
  Y.Squarespace.GoogleDomains.startConsentFlow = function (websiteId, userId, domainName) {
    var spinner = new Y.Squarespace.Spinner({
      color: 'light',
      size: 'large',
      render: Y.one('.spinner-wrapper') });

    spinner.spin();

    return Y.Squarespace.GoogleDomainsConsentService.getConsent(domainName, userId, websiteId).then(
    // accept
    function (result) {
      var domain = new Y.Squarespace.Domains.Models.Domain({
        name: domainName,
        type: DomainTypes.LINKED,
        providerId: DomainProviderId.GOOGLE,
        providerData: {
          userId: result.userId,
          accessToken: result.accessToken,
          subId: result.subId,
          email: result.email } });



      domain.save({}, function (error) {
        spinner.stop();
        if (Y.Lang.isValue(error)) {
          showError(error.responseJSON.message);
          return;
        }
        window.location = '/config';
      });
    },
    // reject
    function (message) {
      spinner.stop();
      showError(message);
    });
  };

  function newLightbox(domainName) {
    var url = 'http://' + window.location.hostname;
    return new Y.Squarespace.Lightbox({
      disableNormalClose: true,
      clickAnywhereToExit: false,
      content: substituteString(
      '<div class="bigtext">' +
      '<div class="title">' + t("Connect Domain: {domainName}") +

      '</div>' +
      '<div class="description">' + t("Your site <b>{url}</b> will be available at <b>http://www.{domainName}</b>.") +







      '</div>' +
      '<div class="buttons">' +
      '<input type="button" class="login-button" name="login" value="' + t("Connect Domain") +

      '"/>' +
      '</div>' +
      '</div>', {
        domainName: domainName,
        url: url }),

      noHeightConstrain: true });

  }

  function startSpinner() {
    var spinner = new Y.Squarespace.Spinner({
      color: 'light',
      size: 'large',
      render: Y.one('.spinner-wrapper') });


    spinner.spin();

    return spinner;
  }

  function createLinkedDomain(domainName) {
    return DomainsAPI.createLinkedDomain({
      name: domainName,
      providerId: DomainProviderId.GOOGLE,
      strategyId: DomainConnectionStrategyId.DOMAINCONNECT });

  }

  function launchPopup() {
    // empty url to circumvent same-origin policy :(
    return window.open(
    '',
    '_blank',
    'status=0,width=780,height=785,left=400,top=400');

  }

  function waitForClose(popup) {
    return new Y.Promise(function (resolve) {
      var interval = setInterval(
      function () {
        if (popup.closed) {
          clearInterval(interval);
          resolve();
        }
      },
      1000);

    });
  }

  function verifyDomainLink(domain) {
    return axios.get('/api/domain-connect/' + domain.id);
  }

  function closePopup(popup) {
    if (!popup.closed) {
      popup.close();
    }
  }

  function executeStrategy(strategy, popup) {
    return new Y.Promise(function (resolve) {
      popup.location = strategy.url;
      resolve();
    });
  }

  function connect(domainName, popup) {
    var domain;
    return createLinkedDomain(domainName).
    then(function (response) {
      domain = response.data;
      return DomainConnectAPI.getStrategy(domain.name);
    }).
    then(function (resp) {
      var strategy = resp.data;
      // now hand the flow over to the Google
      return executeStrategy(strategy, popup);
    }).
    then(function () {
      return waitForClose(popup);
    }).
    then(function () {
      // check if Google called the redirect_uri we provided in strategy.url
      return verifyDomainLink(domain);
    }).
    then(function (rsp) {
      if (!rsp.data.success) {
        // Google does not report errors
        throw 'Google reported an error.';
      }
      // success!
      // Meaning that the user closed the Google popup using either the Connect or Cancel button,
      // both of which call the redirect_uri but do not provide an error parameter.
      // The only real way to check if Google has made the connection is to check
      // DNS for a CNAME <Domain.verificationCode>.<Domain.name> pointing to verify.squarespace.com.
      // (see LinkStatusProberImpl.java) but DNS updates are slow so we don't check now
      // and hope for the best. Failure case shows as user dashboard indicating
      // a DNS settings issue.
    });
  }

  /**
   * Called from HTML file
   * @param domainName
   */
  Y.Squarespace.GoogleDomains.googleInitiatedDomainConnect = function (domainName) {
    var lb = newLightbox(domainName);
    lb.show();
    lb.getContentEl().on('click', function () {
      lb.hide();
      var spinner = startSpinner();
      var popup = launchPopup();
      connect(domainName, popup).
      then(function () {
        window.location = '/config';
      }).
      catch(function (error) {
        closePopup(popup);
        spinner.stop();
        showError(error);
      });
    });
  };

}, '1.0', { requires: [
  'querystring',
  'squarespace-domains-models-domain',
  'squarespace-google-domains-consent-service',
  'squarespace-session-storage',
  'squarespace-spinner',
  'squarespace-ui-base',
  'squarespace-util'] });