"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.DomainConnectionStrategyId */

/* Java class defined in repository squarespace-v6 */
var DomainConnectionStrategyId;

(function (DomainConnectionStrategyId) {
  DomainConnectionStrategyId["MANUAL"] = "manual";
  DomainConnectionStrategyId["DOMAINCONNECT"] = "domainconnect";
})(DomainConnectionStrategyId || (DomainConnectionStrategyId = {}));

var _default = DomainConnectionStrategyId;
exports["default"] = _default;
module.exports = exports.default;