import network, { NetworkPromise } from '@sqs/network';
import DomainConnectionStrategyId from '@sqs/enums/DomainConnectionStrategyId';
import DomainProviderId from '@sqs/enums/DomainProviderId';

const CONNECT_PATH = '/api/domain-connection';
const PROVIDER_PATH = '/api/domain-providers';

export type DomainProvider = {
  id: DomainProviderId;
  name: string;
  providerUrl?: string;
  helpArticleUrl?: string;
};

export type DomainConnectStrategy = {
  strategyId: DomainConnectionStrategyId,
  domainName: string,
  domainConnectUrl?: string,
  providerName?: string,
};

const DomainConnectAPI = {
  getProviders(): NetworkPromise<{results: DomainProvider[]}> {
    return network.get(PROVIDER_PATH);
  },

  identifyProvider(domain: string): NetworkPromise<DomainProvider> {
    return network.get(`${PROVIDER_PATH}/identify?q=${domain}`);
  },

  getStrategyId(domainName: string) : NetworkPromise<DomainConnectStrategy> {
    return network.get(`${CONNECT_PATH}/${domainName}/strategy-id`);
  },

  getStrategy(domainName: string) : NetworkPromise<DomainConnectStrategy> {
    return network.get(`${CONNECT_PATH}/${domainName}`);
  },
};

export default DomainConnectAPI;
