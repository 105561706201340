/**
 * @module squarespace-dialog-description
 */
YUI.add('squarespace-dialog-description', function(Y) {

  Y.namespace('Squarespace.DialogFields');

  /**
   * Dialog Description Field is an extremely simply class for managing descriptions.
   *
   * @namespace Squarespace.DialogFields
   * @class     DialogDescription
   * @extends   Squarespace.DialogField2
   */
  var DialogDescription = Y.Squarespace.DialogFields.Description = Y.Base.create(
    'DescriptionField',
    Y.Squarespace.DialogField2,
    [],
    {

      // -------------------------------------------------------------------------
      // Widget Life Cycle
      // -------------------------------------------------------------------------

      destructor: function () {
        this._descEl = null;
      },

      renderUI: function() {
        DialogDescription.superclass.renderUI.call(this);
        this._descEl = this.get('contentBox').one('.custom-field-description');

        var padding = this.get('padding');
        if (Y.Lang.isArray(padding) && padding.length > 0) {
          this._descEl.setStyles({
            paddingTop: padding[0],
            paddingBottom: padding[1]
          });
        }

        this._attachNode('title');
        this._attachNode('text');
      },

      bindUI: function() {
        DialogDescription.superclass.bindUI.call(this);
        this.after(['titleChange', 'textChange'], this.syncUI, this);
      },

      syncUI: function() {
        DialogDescription.superclass.syncUI.call(this);
        this._descEl.empty();
        this._attachNode('title');
        this._attachNode('text');
      },

      // -------------------------------------------------------------------------
      // Private
      // -------------------------------------------------------------------------

      /**
       * Attach a node which maps directly to an attribute.
       *
       * @method  _attachNode
       * @param  {string} attr The name of an attribute.
       */
      _attachNode: function(attr) {
        var attrValue = this.get(attr);

        if (Y.Lang.isString(attrValue) && attrValue.length > 0) {
          var attrNode = Y.Node.create('<div class="' + attr + '">' + attrValue + '</div>');
          this._descEl.append(attrNode);
        }
      },

      /**
       * Helper function for the attributes.
       * @param  {Mixed} value   A value to string set.
       * @return {String}        Non-values are returned as empty strings, otherwise cast to string.
       */
      _getAsString: function (value) {
        return Y.Lang.isValue(value) ? value + '' : '';
      },

      // -------------------------------------------------------------------------
      // Public
      // -------------------------------------------------------------------------

      /**
       * Set the title on the description, causes a syncUI
       *
       * @method  setTitle
       * @param  {string} title  Title of the field
       */
      setTitle: function(title) {
        this.set('title', title);
      },

      /**
       * Set the description text on the field, leads to a sync UI
       *
       * @method  setText
       * @param  {string} text  The text to set the description to.
       */
      setText: function(text) {
        this.set('text', text);
      }

    },
    {

      CSS_PREFIX: 'sqs-description',

      TEMPLATE: '<div class="custom-field-description"></div>',

      ATTRS: {

        /**
         * @attribute title
         */
        title: {
          value: '',
          getter: this._getAsString
        },

        /**
         * @attribute text
         */
        text: {
          value: '',
          getter: this._getAsString
        },

        /**
         * @attribute className
         * @type      String
         */
        className: {
          value: '',
          validator: Y.Lang.isString
        },

        /**
         * @attribute padding
         * @type      Array
         */
        padding: {
          value: [],
          validator: Y.Lang.isArray
        },

        /**
         * @default false
         * @attribute focusable
         * @type      Boolean
         */
        focusable: {
          value: false
        },

        testAttribute: {
          value: 'description'
        }
      }
    }
  );

  // @TODO remove legacy string-name support in dialog/dialog.js#_convertToDialogField2Name
  Y.Squarespace.DialogFields.DescriptionField = Y.Squarespace.DialogFields.Description;

}, '1.0', {
  requires: [
    'base',
    'node',
    'squarespace-dialog-field-2'
  ]
});
