const PropTypes = require('prop-types');
const React = require('react');
const { Input } = require('@sqs/fields');
const { t } = require('shared/i18n');
const sentinelStyles = require('shared/styles/sentinel.less');

class CommentsGuestLogin extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func,
    onClose: PropTypes.func };


  state = {
    authorUrl: '',
    displayName: '',
    email: '',

    errors: {} };


  handleSubmit = () => {
    if (this.state.displayName === '') {
      this.setState({
        errors: {
          displayName: true } });


    } else {
      this.props.onSubmit(this.state);
    }
  };

  bindField = (key) => {
    return {
      value: this.state[key],
      hasErrors: this.state.errors[key],
      onKeyDown: (e) => {
        if (e.key === 'Enter') {
          this.handleSubmit();
        }
      },
      onChange: (value) => {
        this.setState({
          [key]: value });

      } };

  };

  render() {
    const buttonDisabled = this.state.displayName === '';

    return (
      <div>
        <div className={sentinelStyles.overlay} />
        {/* @TODO consider using a button */}
        {/* eslint-disable-next-line */}
        <div
        className={sentinelStyles.close}
        onClick={this.props.onClose} />


        <i className={sentinelStyles.desktopLogo} />

        <header className={sentinelStyles.mobileHeader}>
          <i className={sentinelStyles.mobileLogo} />
          <h1 className={sentinelStyles.title}>
            {t("Post a Comment")}
          </h1>
        </header>

        <div className={sentinelStyles.container}>
          <header className={sentinelStyles.desktopHeader}>
            <h1 className={sentinelStyles.title}>
              {t("Post a Comment")}
            </h1>
          </header>

          <Input
          theme="midnight"
          placeholder={t("Name")}
          {...this.bindField('displayName')} />

          <Input
          theme="midnight"
          placeholder={t("Email Address")}
          {...this.bindField('email')} />

          <Input
          theme="midnight"
          placeholder={t("Website URL")}
          {...this.bindField('authorUrl')} />


          <button
          disabled={buttonDisabled}
          className={sentinelStyles.button}
          onClick={this.handleSubmit}>

            {t("Comment as Guest")}
          </button>
        </div>
      </div>);

  }}


module.exports = CommentsGuestLogin;