import { html2markdown } from 'src/main/webapp/universal/scripts-thirdparty/markdown/markdown-from-html';
import Marked from 'marked';

YUI.add('squarespace-markdown', function (Y) {
  Y.namespace('Squarespace.Lang');

  Y.Squarespace.Lang.markdownFromHTML = function (input) {

    if (!input) {
      return '';
    }

    return html2markdown(input);
  };

  Y.Squarespace.Lang.markdownToHTML = function (input, breaks) {
    if (!input) {
      return '';
    }

    Marked.setOptions({
      breaks: !!breaks
    });
    return Marked(input);
  };
}, '1.0', { requires: [] });
