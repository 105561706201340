/**
  *********** THE FOLOWING METHODS ARE BEING DEPRECATED! ************
  *
  * @method getConsentForNewSite
  * @method getConsentForDomain
  * @method getDomainOnlyConsent
  * @method getConsentForList
  * @method _openListConsentWindow
  * @method _openDomainConsentOnlyWindow
  * @method _openSignupConsentWindow
  *
  * The functionality here was moved to to LinkProvider/helpers/googleConnect.js
  * These methods will be deleted soon. If you need to add any new functionality do it in
  * the new files.
*/


import { t } from 'shared/i18n';
/**
 * The module containing the google domains consent service.
 * @module squarespace-google-domains-consent-service
 */
YUI.add('squarespace-google-domains-consent-service', function (Y) {

  Y.namespace('Squarespace');

  // Template for starting the basic consent flow
  var CONSENT_INITIALIZE_URL_TEMPLATE = 'https://oauth.{appDomain}/auth/google-web-hosting/consent-initialize?' +
  'domain={domain}&websiteId={websiteId}&userId={userId}';

  // Template for starting the consent flow and using previously-obtained request data
  var DOMAIN_CONSENT_URL_TEMPLATE = 'https://oauth.{appDomain}/auth/google-web-hosting/domain-consent-initialize?' +
  'domain={domain}&requestId={requestId}';

  // Tepmlate for getting consent only to list the domains
  var LIST_CONSENT_URL_TEMPLATE = 'https://oauth.{appDomain}/auth/google-web-hosting/list-domains-consent-initialize?' +
  'requestIdentifier={identifier}';

  // Template for starting the consent flow for a new site
  var SIGNUP_CONSENT_URL_TEMPLATE = 'https://oauth.{appDomain}/auth/google-web-hosting/signup-consent-initialize?' +
  'domain={domain}&userId={userId}';

  /**
   * A service that requests and grants consent for Squarespace to manage Google domains.
   *
   * @class GoogleDomainsConsentService
   * @namespace Squarespace
   * @example
   *   var Service = Y.Squarespace.GoogleDomainConsentService;
   *   Service.getConsent('westballz.com')
   *          .then(function() {
   *            console.log('Consent granted!');
   *          }, function() {
   *            console.log('Consent not granted. :(');
   *          });
   */
  Y.Squarespace.GoogleDomainsConsentService = new (Y.Base.create('domainConsentService', Y.Base, [], {
    initializer: function () {

      // The set of open windows we monitor for user closes. if a user closes the popup window via the X button
      // or something, we consider that a consent not granted.
      this._openWindows = {};
      this._messageCallbacks = {};
      this._messageEventHandler = window.addEventListener('message', Y.bind(this._routeMessage, this));
    },

    destructor: function () {
      window.removeEventListener('message', this._messageEventHandler);
      this._endMonitor();
    },

    /**
     * Get consent for a domain from Google Web Hosting
     *
     * @method getConsent
     * @param domain {String} The domain name
     * @param userId {String} The google plus user id of the owner of the domain.
     * @param [websiteId] {String} The id of the website, or none if it's a new site.
     * @return {Promise} A promise that resolves if consent was granted by the user, and rejects otherwise.
     */
    getConsent: function (domain, userId, websiteId) {
      return Y.Squarespace.Promises.create(function (resolve, reject) {
        this._messageCallbacks[domain] = function (result) {
          if (result.consentGranted) {
            resolve(result);
          } else {
            reject();
          }
        };

        this._openConsentWindow(domain, userId, websiteId);
      }, this);
    },

    getConsentForNewSite: function (domain, userId) {
      return Y.Squarespace.Promises.create(function (resolve, reject) {
        this._messageCallbacks[domain] = function (result) {
          if (result.consentGranted) {
            resolve(result);
          } else {
            reject();
          }
        };

        this._openSignupConsentWindow(domain, userId);
      }, this);
    },

    getConsentForDomain: function (domain) {
      return Y.Squarespace.Promises.create(function (resolve, reject) {
        this._messageCallbacks[domain] = function (result) {
          if (result.consentGranted) {
            resolve(result);
          } else {
            reject(result);
          }
        };

        // don't ask. unless you need to.
        this._openListConsentWindow(domain);
      }, this);
    },

    getDomainOnlyConsent: function (domain, requestId, websiteId) {
      return Y.Squarespace.Promises.create(function (resolve, reject) {
        this._messageCallbacks[domain] = function (result) {
          if (result.consentGranted) {
            resolve(result);
          } else {
            reject();
          }
        };

        this._openDomainConsentOnlyWindow(domain, requestId, websiteId);
      }, this);
    },

    getConsentForList: function () {
      return Y.Squarespace.Promises.create(function (resolve, reject) {

        // Use a random domain...
        var domain = Y.guid();
        this._messageCallbacks[domain] = function (result) {
          if (result.consentGranted) {
            resolve(result);
          } else {
            reject();
          }
        };

        this._openListConsentWindow(domain);
      }, this);
    },

    _routeMessage: function (message) {
      var domain = message.data.domain;

      if (Y.Lang.isString(domain)) {
        var callback = this._messageCallbacks[domain];

        if (Y.Lang.isFunction(callback)) {
          callback.call(this, message.data);
          delete this._messageCallbacks[domain];
        }
      }
    },

    _registerOpenWindow: function (key, popup) {
      if (Y.Object.size(this._openWindows) <= 0) {
        this._beginMonitor();
      }
      this._openWindows[key] = popup;
    },

    _unregisterOpenWindow: function (key) {
      delete this._openWindows[key];
      if (Y.Object.size(this._openWindows) <= 0) {
        this._endMonitor();
      }
    },

    _endMonitor: function () {
      if (this._monitor) {
        this._monitor.cancel();
      }
    },

    _beginMonitor: function () {
      this._monitor = Y.later(300, this, function () {
        var keysToRemove = [];

        // Check if any windows have been closed and route their events
        // with 'consentGranted: false'
        Y.Object.each(this._openWindows, function (popup, domain) {
          if (popup.closed) {
            this._routeMessage({
              data: {
                domain: domain,
                consentGranted: false } });



            keysToRemove.push(domain);
          }
        }, this);

        // Remove from the window registry any windows we've detected to be closed
        Y.Array.each(keysToRemove, this._unregisterOpenWindow, this);
      }, null, true);
    },

    _openListConsentWindow: function (domain) {
      var loc = Y.Lang.sub(LIST_CONSENT_URL_TEMPLATE, {
        identifier: domain,
        appDomain: Static.SQUARESPACE_CONTEXT.appDomain });


      this._openConsentPopup(loc, domain);
    },

    _openDomainConsentOnlyWindow: function (domain, requestId, websiteId) {
      var loc = Y.Lang.sub(DOMAIN_CONSENT_URL_TEMPLATE, {
        requestId: requestId,
        domain: domain,
        websiteId: websiteId,
        appDomain: Static.SQUARESPACE_CONTEXT.appDomain });


      this._openConsentPopup(loc, domain);
    },

    _openConsentWindow: function (domain, userId, websiteId) {
      var loc = Y.Lang.sub(CONSENT_INITIALIZE_URL_TEMPLATE, {
        domain: domain,
        appDomain: Static.SQUARESPACE_CONTEXT.appDomain,
        websiteId: websiteId || '',
        userId: userId });


      this._openConsentPopup(loc, domain);
    },

    _openSignupConsentWindow: function (domain, userId) {
      var loc = Y.Lang.sub(SIGNUP_CONSENT_URL_TEMPLATE, {
        domain: domain,
        userId: userId,
        appDomain: Static.SQUARESPACE_CONTEXT.appDomain });


      this._openConsentPopup(loc, domain);
    },

    _openConsentPopup: function (url, domain) {
      var popup = window.open(url, t("Google Domains Consent"),

      'status=0,width=1020,height=520');
      this._registerOpenWindow(domain, popup);
    } }))();

}, '1.0', {
  requires: [
  'base',
  'promise',
  'squarespace-promises'] });