import CommentStatuses from '@sqs/enums/CommentStatuses';
import noop from 'lodash/noop';

/**
* Holds the Y.Squarespace.CommentData Class
* @module squarespace-comment-data
*/
YUI.add('squarespace-comment-data', function(Y) {

  Y.namespace('Squarespace');

  /**
  * Handles comment data
  * @class CommentData
  * @namespace Squarespace
  * @constructor
  * @param config { Object } Configuration options are as follows:
  * @param config.formatter { Function } A function that takes as arguments an array of comment data,
  *   a map from ObjectId to boolean indicating whether the current authenticated user has
  *   liked the comment with that id and another map from ObjectId to boolean for flags.
  * @param config.targetType { Number } A TargetType as defined in util.js
  * @param config.target { String } : ObjectId of the ContentItem or ContentCollection to which the comments refer.
  */
  Y.Squarespace.CommentData = Class.create({

    initialize: function(config) {

      // Fatal errors
      if (config === undefined) {

        throw new Error('You must provide a configuration object.');
      } else if (config.target === undefined) {

        throw new Error('You must provide a target id.');
      } else if (config.targetType === undefined) {

        throw new Error('You must provide a target type.');
      }

      // Until we nail down specific forum settingss (or if there will be any),
      // forum module wont provide any settings so we will prepare for its nonexistance
      // with a dummy object w/ debug settings.
      this.settings = (config.settings || {
        approvalRequired: false,
        allowAnon: true,
        threaded: true
      });

      // This function, which takes the server's response to GetComments is called after comments are retrieved to
      // store them in a specified format. (Used to modify JSON for template rendering.)
      this.formatter = (config.formatter || function(data) {
        return data;
      });

      this.page = 1;

      // The formatted comment data.
      this.data = {
        comments: []
      };

      this.topLevelComments = 0;
      this.visibleComments = 0;
      this.totalComments = config.publicCommentCount || 0;

      this.commentsPerPage = 50;

      // The contentItem or contentCollection this instance will act on
      this.target = config.target;
      this.targetType = config.targetType;

      this.idToIndex = {};
      this.comments = [];
      this.likeMap = {};
      this.flagMap = {};
      this.formatted = {};
      this.currentThreadRoot = '';
      this.formattedThread = {};

      // Keep an array of pairs indicating the interval to slice out of
      // the comments array to acquire a single page. Useful for
      // rendering only a single page at a time. (For example,
      // appending a new page to the list.)
      //
      // Warning: These pairs can have undefined as a value, which _is_ valid
      // in this case because [1,2,3,4].slice(0, undefined) returns [1,2,3,4].
      // Keeping undefined for the end interval allows us to naively do
      // Array.prototype.slice.apply(array, interval) without worry.
      this.pageIntervals = [];
    },

    useSecure: function() {
      return (window.SQUARESPACE_LOGIN && window.SQUARESPACE_LOGIN.isLoggedIn());
    },

    /**
     * Getter for unformatted data.
     * @method getCommentById
     * @param id { String } ObjectId String of the comment to be returned.
     * @return { Object } Object with the comment's unformatted data as properties.
     */
    getCommentById: function(id) {
      return this.comments[this.idToIndex[id]];
    },

    /**
     * Remove a comment from the database.
     * @method removeCommentById
     * @param id { String } ObjectId string of the comment to be removed.
     */
    removeCommentById: function(id) {
      var idx = this.idToIndex[id];
      if (!Y.Lang.isUndefined(idx)) {
        this.comments.splice(idx, 1);
        this.calculatePagination();
      }
    },

    removeCommentsByIds: function(ids) {
      ids.forEach(function(id) {
        var idx = this.idToIndex[id];
        if (!Y.Lang.isUndefined(idx)) {
          this.comments.splice(idx, 1);
          this.visibleComments -= 1;
          this.totalComments -= 1;
        }
      }, this);
      this.calculatePagination();
    },
    /**
     * Getter for formatted data, by page.
     * @method getFormattedPage
     * @param page { Number } The number of the page of comments to be returned, starting from 1.
     * @return { Array } Array of comment Objects that have been run through the formatter.
     */
    getFormattedPage: function(page) {
      // Return the formatted data for a single page.
      var interval = this.pageIntervals[page - 1];
      if (interval) {
        return Array.prototype.slice.apply(this.comments, interval);
      }
      return null;

    },

    /**
     * Perform bookkeeping and data aggregation for a full refresh of the comment
     * data. Clears comments array, page intervals and index.
     * @method fullBookkeeping
     * @param data { Object } Data returned from GetComments
     * @private
     */
    fullBookkeeping: function(data) {
      var i = 0,
        comment = {};

      this.comments = [];
      this.pageIntervals = [];
      this.idToIndex = {};
      for (i = 0; i < data.comments.length; i++) {
        comment = data.comments[i];
        this.idToIndex[comment.id] = i;
        this.comments[i] = comment;
      }

      this.pageIntervals.push([0, undefined]);
      this.flagMap = data.flagMap;
      this.likeMap = data.likeMap;
      this.commentSettings = data.commentSettings;

      this.visibleComments = data.visibleComments;
      this.topLevelComments = (this.formatted.topLevelComments || this.topLevelComments);
      this.totalComments = (data.totalComments ? data.totalComments : this.totalComments);

      this.reformat();
      this.topLevelComments = data.topLevelComments;
      this.numLikes = data.numLikes;

      this.numPages = Math.ceil(this.topLevelComments / this.commentsPerPage);
    },

    /**
     * Perform bookkeeping for adding a single comment in an arbitrary location
     * in the data....oh joy.
     * @method addCommentData
     * @param data { Object } Comment data to be added.
     */
    addCommentData: function(data) {

      // Insert it right after its parent in the comments array
      this.comments.splice((this.idToIndex[data.parentId] || -1) + 1, 0, data);
      this.calculatePagination();
    },

    /**
     * Compute pagination offset and index table.
     * @method calculatePagination
     * @private
     */
    calculatePagination: function() {
      var commentsPerPage = 50,
        topLvlCommentsThisPage = 0,
        lastInterval,
        comment,
        i;
      this.idToIndex = {};
      this.pageIntervals = [[0, undefined]];
      for (i = 0; i < this.comments.length; i++) {
        comment = this.comments[i];
        this.idToIndex[comment.id] = i;

        if (!comment.parentId) {
          topLvlCommentsThisPage += 1;

          // We know we've crossed a page threshold if we have seen
          // more than commentsPerPage top-level comments.
          if (topLvlCommentsThisPage > commentsPerPage) {
            lastInterval = this.pageIntervals[this.pageIntervals.length - 1];

            // Cap off the last interval
            lastInterval[1] = topLvlCommentsThisPage - 1;

            // Make a new last interval
            this.pageIntervals.push([lastInterval[1], undefined]);
            topLvlCommentsThisPage = 0;
          }
        }
      }
    },

    /**
     * Perform bookkeeping for the case where we add new comments to the dataset,
     * but keep the old ones. Udpdates the comments array, page intervals and index map.
     * @method appendBookkeeping
     * @param data { Object } Data returned from GetComments
     * @private
     */
    appendBookkeeping: function(data) {
      var i = 0,
        comment = {};

      // Cap off the previous slice interval.
      this.pageIntervals[this.pageIntervals.length - 1][1] = this.comments.length;

      for (i = 0; i < data.comments.length; i++) {
        comment = data.comments[i];
        this.idToIndex[comment.id] = (this.comments.length + 1);
        this.comments.push(comment);
      }

      this.pageIntervals.push([this.pageIntervals[this.pageIntervals.length - 1][1], undefined]);

      this.likeMap = Y.merge(this.likeMap, data.likeMap);
      this.flagMap = Y.merge(this.flagMap, data.flagMap);

      this.visibleComments += data.visibleComments;
      this.reformat();
    },

    /**
     * Runs the formatter and sets this.formatted to the result.
     * @method reformat
     * @private
     */
    reformat: function() {
      this.formatted = this.formatter(this.comments, this.likeMap, this.flagMap);
    },

    /**
     * Like a comment.
     * @method like
     * @param config.success { Object } Configuration options are as follows:
     * @param config.success { Function } : Callback to be executed on successful request.
     * @param config.failure { Function } : Callback to be executed on unsuccessful request.
     * @param config.id { String } :        ObjectId of the comment to act upon.
     * @param context { Object } "this" Object to which the supplied callbacks will be bound. If omitted,
     * the comment data object will be used instead.
     */
    like: function(config, context) {
      var successFunc = function(data) {
          var comment = this.getCommentById(config.id);
          if (comment) {
            comment.likeCount += 1;
            this.reformat();
          }

          (config.success || noop).call((context || this), data);
        },
        failureFunc = function(data) {
          (config.failure || noop).call((context || this), data);
        };

      Y.Data.post({
        url: '/api/comment/LikeComment',
        secure: true,
        data: {
          commentId: config.id
        },
        success: successFunc,
        failure: failureFunc
      }, this);
    },

    /**
     * Unlike a comment.
     * @method unlike
     * @param config { Object } Configuration options are as follows:
     * @param config.success { Function } : Callback to be executed on successful request.
     * @param config.failure { Function } : Callback to be executed on unsuccessful request.
     * @param config.id { String } :        ObjectId of the comment to act upon.
     * @param context { Object } "this" Object to which the supplied callbacks will be bound. If omitted,
     * the comment data object will be used instead.
     */
    unlike: function(config, context) {

      var successFunc = function(data) {
        var comment = this.getCommentById(config.id);
        if (comment) {
          comment.likeCount = Math.max(comment.likeCount - 1, 0);
          this.reformat();
        }

        (config.success || noop).call((context || this), data);
      };

      Y.Data.post({
        url: '/api/comment/UnlikeComment',
        secure: true,
        data: {
          commentId: config.id
        },
        success: successFunc,
        failure: config.failure
      }, this);
    },

    flag: function(config, context) {
      var successFunc = function(data) {

          var comment = this.getCommentById(config.id);
          if (comment) {
            comment.flagCount += 1;
            this.reformat();
          }
          (config.success || noop).call((context || this), data);
        },
        failureFunc = function(resp) {
          (config.failure || noop).call((context || this), resp);
        };


      Y.Data.post({
        url: '/api/comment/FlagComment',
        secure: true,
        data: {
          commentId: config.id
        },
        success: successFunc,
        failure: failureFunc
      }, this);

    },

    _delete: function(config, context) {
      var successFunc = function(data) {
        config.comments.forEach(function(c) {
          this.removeCommentById(c.id);
        }, this);
        this.visibleComments -= config.comments.length;
        this.totalComments -= config.comments.length;
        (config.success || noop).call((context || this), data);
      };

      Y.Data.post({
        url: '/api/comment/admin/DeleteComments',
        secure: true,
        data: {
          markAsSpam: config.spam,
          comments: (Y.Lang.isArray(config.comments) ? config.comments : [config.comments])
        },
        success: successFunc,
        failure: (config.failure ? Y.bind(config.failure, (context || this)) : undefined)
      }, this);
    },

    approve: function(config, context) {
      var successFunc = function(data) {
        config.comments.forEach(function(c) {
          var comment = this.getCommentById(c.id);
          if (comment) {
            comment.status = CommentStatuses.APPROVED;
          }
        }, this);
        this.totalComments += 1;
        (config.success || noop).call((context || this), data);
      };

      Y.Data.post({
        url: '/api/comment/admin/ApproveComments',
        secure: true,
        data: {
          comments: (Y.Lang.isArray(config.comments) ? config.comments : [config.comments])
        },
        success: successFunc,
        failure: (config.failure ? Y.bind(config.failure, (context || this)) : undefined)
      }, this);
    },

    unflag: function(config, context) {
      var successFunc = function(data) {
          var comment = this.getCommentById(config.id);

          if (comment) {
            comment.flagCount = Math.max(comment.flagCount - 1, 0);
            this.reformat();
          }
          (config.success || noop).call((context || this), data);
        },
        failureFunc = function(resp) {
          (config.failure || noop).call((context || this), resp);
        };

      Y.Data.post({
        url: '/api/comment/UnflagComment',
        secure: true,
        data: {
          commentId: config.id
        },
        success: successFunc,
        failure: failureFunc
      }, this);
    },

    post: function(config, context) {

      var data = {
          targetId: this.target,
          targetType: this.targetType,
          body: config.body,
          replyToId: config.replyTo,
          authorName: (config.authorName || ''),
          authorEmail: (config.email || ''),
          authorUrl: (config.authorUrl || '')
        },
        successFunc = function(respData) {

          if (respData.comment.status === CommentStatuses.APPROVED) {
            this.addCommentData(respData.comment);
            this.visibleComments += 1;
            this.totalComments += 1;
            if (!config.replyTo) {
              this.topLevelComments += 1;
            }
            this.reformat();
          }

          (config.success || noop).call((context || this), respData);
        };

      Y.Data.post({
        url: '/api/comment/CreateComment',
        secure: (!!Static.SQUARESPACE_CONTEXT.authenticatedAccount),
        data: data,
        success: successFunc,
        failure: (config.failure ? Y.bind(config.failure, (context || this)) : undefined)
      }, this);
    },

    getPage: function(config, page, context) {
      if (page <= this.numPages) {
        this.get({
          sortBy: config.order,
          page: page,
          success: config.success,
          failure: config.failure
        }, context);
      }
    },

    getSince: function(config, since, context) {
      this.get({
        since: since,
        success: config.success,
        failure: config.failure
      }, context);
    },

    getThread: function(config, context) {
      var successFunc = function(data) {

          this.currentThreadRoot = config.commentId;

          var rootDepth = data.comments[0].comment.depth;
          data.comments = Y.Array.map(data.comments, function(n) { return n.comment; });
          data.comments.forEach(function(n) { n.depth = (n.depth - rootDepth); });

          this.formattedThread = this.formatter(data.comments, {}, {});
          if (Y.Lang.isFunction(config.success)) {
            config.success.call((context || this), data);
          }
        },
        failureFunc = function(response) {
          if (Y.Lang.isFunction(config.failure)) {
            config.failure.call((context || this), response);
          }
        };

      Y.Data.get({
        url: '/api/comment/GetThread',
        secure: this.useSecure(),
        data: {
          sortBy: config.order,
          commentId: config.commentId
        },
        success: successFunc,
        failure: failureFunc
      }, this);
    },

    get: function(config, context) {

      var successFunc = function(data) {
        this.page = config.page;

        if (config.page <= 1) {
          this.fullBookkeeping(data);
        } else if (config.page > 1) {
          this.appendBookkeeping(data);
        }

        if (config.success) {
          config.success.call((context || this), data);
        }
      };


      Y.Data.get({
        url: '/api/comment/GetComments',
        secure: this.useSecure(),
        data: {
          targetId: this.target,
          targetType: this.targetType,
          since: (config.since || ''),
          page: config.page,
          sortBy: config.order
        },
        success: successFunc,
        failure: config.failure
      }, this);
    },

    getLikesAndFlags: function(config, context) {
      var ids = Y.Array.map(this.comments, function(n) { return n.id; });

      Y.Data.get({
        url: '/api/comment/GetLikesFlags',
        secure: this.useSecure(),
        data: {
          ids: ids
        },
        success: function(data) {
          if (config.success && Y.Lang.isFunction(config.success)) {
            config.success.call((context || this), data);
          }
        },
        failure: function(data) {
          if (config.failure && Y.Lang.isFunction(config.failure)) {
            config.failure.call((context || this), data);
          }
        }
      });
    }
  });

}, '1.0', { requires: ['squarespace-util'] });
