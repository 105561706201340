import Money from '@sqs/i18n-ui/lib/money';
import DomainSubscriptionState from '@sqs/enums/DomainSubscriptionState';
import DomainPlanLevel from '@sqs/enums/DomainPlanLevel';

/**
 * Domain Data Model.
 *
 * @module squarespace-domains-models-domain
 */
YUI.add('squarespace-domains-models-domain', function(Y) {

  Y.namespace('Squarespace.Domains.Models');

  /**
   * The Domain data model.
   *
   * NOTE: Do NOT add any attrs to this model that are not directly provided by the server.
   * This model is meant to reflect the server model. Please wrap this object if
   * you need to add further logic.
   *
   * @class Domain
   * @constructor
   * @namespace Squarespace.Domains.Models
   * @extends Model
   */
  var Domain =
  Y.Squarespace.Domains.Models.Domain =
  Y.Base.create('Domain', Y.Model, [Y.Squarespace.ModelSync.REST], {
    // /api/rest/domains
    root: Y.Squarespace.REST_API_ROOT + 'domains',

    // Override
    serialize: function() {
      var jsonified = Domain.superclass.toJSON.apply(this, arguments);
      // The API is failing on turning Autorenew off for Free for 1 year domains.
      // These fields are irrelevant for the update, and are causing trouble.
      // So delete them from the payload
      delete jsonified.discountExpiresOn;
      delete jsonified.planLevel;
      delete jsonified.certificates;
      if (jsonified.basePrice instanceof Money) {
        jsonified.basePrice = jsonified.basePrice.toJson();
      }
      var result = Y.JSON.stringify(jsonified);
      return result;
    }

  }, {

    ATTRS: {
      /**
       * The domain's id.
       *
       * @attribute id
       * @type String
       */
      id: {
        validator: Y.Squarespace.AttrValidators.isString
      },

      /**
       * Auto-renew dictates whether or not the user will automatically
       * be charged when this domain is about to expire. This only applies to
       * managed domains.
       *
       * @attribute autoRenew
       * @type Boolean
       */
      autoRenew: {
        validator: Y.Squarespace.AttrValidators.isBoolean
      },

      /**
       * The timestamp for when the domain is scheduled to auto-renew. Usually
       * 15 days before the domain is scheduled to expire. If auto-renew is turned
       * off, this value does not apply. It also only applies to managed domains.
       *
       * @attribute renewsOn
       * @type Number
       */
      renewsOn: {
        validator: Y.Squarespace.AttrValidators.isNullOrNumber
      },

      /**
       * The timestamp for when the domain was purchased from Squarespace. This
       * only applies to managed domains.
       *
       * @attribute createdOn
       * @type Number
       */
      createdOn: {
        validator: Y.Squarespace.AttrValidators.isNumber
      },

      certificates: {
        validator: Y.Squarespace.AttrValidators.isArray
      },

      discountExpiresOn: {
        setter: function(val) {
          if (val instanceof Date) {
            return val;
          } else if (Y.Lang.isNumber(val)) {
            return new Date(val);
          }
          return null;
        }
      },

      /**
       * Whether or not the user has been verified as the owner of this linked
       * domain. This only applies to linked domains.
       *
       * @attribute isVerified
       * @type Boolean
       */
      isVerified: {
        validator: Y.Squarespace.AttrValidators.isBoolean
      },

      /**
       * Whether or not to allow the transfer of this domain. Turning it off
       * turns on the ability to fetch a transfer key that can be used to
       * transfer this domain away from Squarespace. This only applies
       * to managed domains.
       *
       * @attribute locked
       * @type Boolean
       */
      locked: {
        validator: Y.Squarespace.AttrValidators.isBoolean
      },

      /**
       * The expiration timestamp for this domain. Only applies to managed
       * domains.
       *
       * @attribute managedDomainExpiration
       * @type Number
       */
      managedDomainExpiration: {
        validator: Y.Squarespace.AttrValidators.isNullOrNumber
      },

      /**
       * The domain name.
       *
       * @attribute name
       * @type String
       */
      name: {
        validator: Y.Squarespace.AttrValidators.isString
      },

      /**
       * The plan level
       *
       * @attribute planLevel
       * @type String
       */
      planLevel: {
        validator: Y.Squarespace.AttrValidators.isValueIn(DomainPlanLevel)
      },

      /**
       * The price at which this domain was purchased. Is 0 for free domains.
       * This only applies to managed domains.
       *
       * @attribute basePrice
       * @type Money
       */
      basePrice: {
        value: null,

        // This value is set from a myriad of places.
        // Deserialize the Money value if it is not already.
        // This way we have a predictable and correct Money here.
        setter: function(val) {
          if (Y.Lang.isObject(val) && !(val instanceof Money)) {
            val = Money.fromJson(val);
          }

          // validator. Ordinary validatior comes before the setter.
          if (val !== null && !(val instanceof Money)) {
            return Y.Attribute.INVALID_VALUE;
          }
          return val;
        }
      },

      /**
       * The id of the 3rd party domain provider. This only applies to linked
       * domains.
       *
       * @attribute providerId
       * @type String
       */
      providerId: {
        validator: Y.Squarespace.AttrValidators.isNumber
      },

      /**
       * Whether or not google apps has been purchased for this domain.
       *
       * @attribute purchasedGoogleApps
       * @type Boolean
       */
      purchasedGoogleApps: {
        validator: Y.Squarespace.AttrValidators.isBoolean
      },

      /**
       * Whether or not this domain can be canceled. There is a 5 day period
       * from the day the domain is purchased where this is possible. This only
       * applies to managed domains.
       *
       * @attribute revocable
       * @type Boolean
       */
      revocable: {
        validator: Y.Squarespace.AttrValidators.isBoolean
      },

      /**
       * The subscription Id for this domain.
       *
       * @attribute subscriptionId
       * @type String
       */
      subscriptionId: {
        validator: Y.Squarespace.AttrValidators.isString
      },

      /**
       * The state of the billing subscription for this purchased domain. Can
       * be active or expired. See Squarespace.DomainSubscriptionState in enum.
       * This only applies to managed domains.
       *
       * @attribute subscriptionState
       * @type Number
       */
      subscriptionState: {
        validator: Y.Squarespace.AttrValidators.isValueIn(DomainSubscriptionState)
      },

      /**
       * Type is used to determine where the domain was obtained. See
       * Squarespace.DomainTypes in enum.
       *
       * @attribute type
       * @type Number
       */
      type: {
        validator: Y.Squarespace.AttrValidators.isNumber
      },

      /**
      *
       * @attribute verificationCode
       * @type String
       */
      verficationCode: {
        validator: Y.Squarespace.AttrValidators.isNullOrString
      },

      /**
       * Whether or not the domain is still propagating. This is a loose guess.
       * It is set to true if the domain was purchased in the last 24 hours.
       * This only applies to managed domains.
       *
       * @attribute withinPropagationWindow
       * @type Boolean
       */
      withinPropagationWindow: {
        validator: Y.Squarespace.AttrValidators.isBoolean
      },

      /**
       * Whether or not the domain's whois registration information is private.
       *
       * @attribute whoisPrivacy
       * @type Boolean
       */
      whoisPrivacy: {
        validator: Y.Squarespace.AttrValidators.isNullOrBoolean
      }

    }
  });

}, '1.0', {
  requires: [
    'base',
    'model',
    'squarespace-attr-validators',
    'squarespace-model-sync-rest'
  ]
});
