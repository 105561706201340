import React from 'react';
import ReactDOM from 'react-dom';
import CookieCutter from '@sqs/cookie-cutter';
import CommentsGuestLogin from 'apps/CommentsGuestLogin';
import Singleton from 'shared/utils/Singleton';

var OAuthLoginStrategy = {

  init: function (Y) {

    return {

      ready: function () {
        if (Static.SQUARESPACE_CONTEXT) {
          this.account = Static.SQUARESPACE_CONTEXT.authenticatedAccount;
        }
      },

      configure: function () {
      },

      /**
       * @method isLoggedIn
       * @return {Boolean}
       */
      isLoggedIn: function () {
        return !!this.account;
      },

      /**
       * @method getAccount
       */
      getAccount: function () {
        return this.account;
      },

      /*
       * show guest login for comments.
       */
      showCommentLogin: function () {
        var mountNode = document.createElement('div');

        document.body.appendChild(mountNode);

        var closeGuestLogin = function() {
          ReactDOM.unmountComponentAtNode(mountNode);
          document.body.removeChild(mountNode);
        };

        ReactDOM.render(
          React.createElement(CommentsGuestLogin, {
            onSubmit: function(result) {
              this.fireGuestLoginEvent(result);
              closeGuestLogin();
            }.bind(this),
            onLoginClick: function() {
              closeGuestLogin();
              this.showOAuthLogin();
            }.bind(this),
            onClose: function() {
              closeGuestLogin();
              Y.config.win.SQUARESPACE_LOGIN.fire('cancel');
            }
          }),
          mountNode
        );
      },

      fireGuestLoginEvent: function (result) {
        CookieCutter.set('email', result.email, { path: '/' });
        CookieCutter.set('displayName', result.displayName, { path: '/' });
        CookieCutter.set('authorUrl', result.authorUrl, { path: '/' });
        Y.config.win.SQUARESPACE_LOGIN.fire('guestLogin');
      }
    };
  }
};

/**
 * @module squarespace-login
 */
YUI.add('squarespace-login', function(Y) {

  if (!Y.config.win.SQUARESPACE_LOGIN) {
    Y.config.win.SQUARESPACE_LOGIN = Singleton.create(OAuthLoginStrategy.init(Y));
    Y.augment(Y.config.win.SQUARESPACE_LOGIN, Y.EventTarget);

    /**
     * Login UI
     * @class SQUARESPACE_LOGIN
     * @namespace config.win
     * @uses EventTarget
     * @static
     */
    Y.config.win.SQUARESPACE_LOGIN_UTILS = {
      /**
       * A promise that initializes the SQUARESPACE_LOGIN object.
       * Frontend login code (such as on frontsite) generally must wait for this promise to be resolved before executing
       */
      loginPromise: new Y.Promise(function(resolve) {
        //TODO: can remove this, users of it need to change first though (e.g. frontsite)
        resolve(true);
      })
    };
  }

}, '1.0', {
  requires: [
    'promise',
    'squarespace-google-domains-utils'
  ]
});
