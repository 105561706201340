import axios from '@sqs/network';
import isNumber from 'lodash/isNumber';
import DomainTypes from '@sqs/enums/DomainTypes';
import LinkStatusType from '@sqs/enums/LinkStatusType';
import DomainVerificationStatus from '@sqs/enums/DomainVerificationStatus';
import DomainProviderId from '@sqs/enums/DomainProviderId';
import DomainConnectionStrategyId from '@sqs/enums/DomainConnectionStrategyId';
import { Domain } from 'shared/types/Domain';
import { NormalizedTldInfo, DomainTldInfo } from './DomainTldInfoAPI';
import { DomainContact } from './DomainContactAPI';
import DnsRecordType from '@sqs/enums/DnsRecordType';

const DOMAINS_ROOT_PATH = '/api/rest/domains';
const DOMAINS_NEW_PATH = '/api/domains';

export type EmailVerificationDetails = {
  daysToSuspend: number;
  verificationDeadline: number; // Date timestamp
  status: DomainVerificationStatus;
  sentToEmail: string;
};

export type PendingRegistrantChangeInfo = {
  pendingChangeId: string;
  oldEmail: string;
  newEmail: string;
};

export type DomainWhoisInfo = {
  admin: DomainContact;
  billing: DomainContact;
  owner: DomainContact;
  tech: DomainContact;
  tldInfo: DomainTldInfo;
  pendingRegistrantChangeInfo: PendingRegistrantChangeInfo;
};

export type LinkStatus = {
  subDomain: string;
  recordType: DnsRecordType;
  expectedRecordType?: DnsRecordType;
  value: string;
  expectedValue?: string;
  type: LinkStatusType;
  deprecated?: boolean;
};

const DomainsAPI = {
  loadDomains(type: DomainTypes) {
    return axios.get(DOMAINS_ROOT_PATH, {
      params: {
        type: isNumber(type) ? type : null
      }
    });
  },

  renew(id: string) {
    return axios.post(DOMAINS_ROOT_PATH + `/${id}/renewer`);
  },

  renewAsPaid(id: string) {
    return axios.post(DOMAINS_ROOT_PATH + `/${id}/renewer/as-paid`);
  },

  getEmailVerificationDetails(id: string) {
    return axios.get<EmailVerificationDetails>(DOMAINS_ROOT_PATH + `/${id}/email-verification-status`);
  },

  sendVerificationEmail(domain: Domain) {
    return axios.post(DOMAINS_ROOT_PATH + `/${domain.id}/verification-email`, domain);
  },

  updateSiteIdentifier(identifier: string) {
    return axios.post('/api/domains/UpdateSiteIdentifier?squarespaceId=' + identifier);
  },

  // takes an update in the form of { name: <field name>, value: <field value> }
  updateDomainField(domain: Domain, update: any) {
    return axios.put(DOMAINS_ROOT_PATH + `/${domain.id}/update-field`, update);
  },

  deleteDomain(id: string) {
    return axios.delete(DOMAINS_ROOT_PATH + `/${id}`);
  },

  getDomainByName(name: string) {
    return axios.get(DOMAINS_NEW_PATH + `/${name}`);
  },

  createLinkedDomain(data: {
    name: string;
    providerId: DomainProviderId,
    strategyId: DomainConnectionStrategyId
  }) {
    return axios.post(DOMAINS_ROOT_PATH, data);
  },

  getLinkStatus(id: string) {
    return axios.get<LinkStatus[]>(DOMAINS_ROOT_PATH + `/${id}/link-status`);
  },

  getWhoisInfo(id: string, contactType = 'single') {
    return axios.get<DomainWhoisInfo>(DOMAINS_ROOT_PATH + `/${id}/whois/${contactType}`);
  },

  getAllWhoisRecords(id: string) {
    return axios.get(DOMAINS_ROOT_PATH + `/${id}/whois`)
      .then((whoIsResponse) => {
        const { pendingRegistrantChangeInfo } = whoIsResponse.data || {};

        if (pendingRegistrantChangeInfo?.pendingChangeId) {
          return DomainsAPI.getRegistrantChangeContactInfo(id, pendingRegistrantChangeInfo.pendingChangeId)
            .then((resp) => {
              return {
                ...(whoIsResponse.data || {}),
                pendingRegistrantContactInfo: resp.data
              };
            });
        }

        return whoIsResponse.data || {};
      });

  },

  updateWhoisContact({ data, domainId, contactType }: {
    data: any,
    domainId: string;
    contactType: string;
  }) {
    return axios.put(DOMAINS_ROOT_PATH + `/${domainId}/whois/${contactType}`, data);
  },

  updateWhoisTldInfo({ tldInfo, domainId }: {
    tldInfo: NormalizedTldInfo;
    domainId: string;
  }) {
    return axios.put(DOMAINS_ROOT_PATH + `/${domainId}/whois/tld-info`, tldInfo);
  },

  requestCertificate(id: string) {
    return axios.post(DOMAINS_ROOT_PATH + `/${id}/certificate`);
  },

  sendAuthcode(id: string) {
    return axios.get(DOMAINS_ROOT_PATH + `/${id}/send-authcode`);
  },

  resendContactChangeApprovalEmail(domainId: string, pendingRegistrantChangeId: string) {
    return axios.post(DOMAINS_ROOT_PATH + `/${domainId}/whois/change/${pendingRegistrantChangeId}/resend`);
  },

  cancelContactChange(domainId: string, pendingRegistrantChangeId: string) {
    return axios.delete(DOMAINS_ROOT_PATH + `/${domainId}/whois/change/${pendingRegistrantChangeId}`);
  },

  getRegistrantChangeContactInfo(domainId: string, pendingRegistrantChangeId: string) {
    return axios.get(DOMAINS_ROOT_PATH + `/${domainId}/whois/change/${pendingRegistrantChangeId}`);
  }
};

export default DomainsAPI;
