import CookieCutter from '@sqs/cookie-cutter';

/**
 * The module containing the Squarespace.SessionStorage class.
 *
 * @module squarespace-session-storage
 */
YUI.add('squarespace-session-storage', function(Y) {

  Y.namespace('Squarespace');

  var _hasLocalStorage = function() {
    return Y.Lang.isValue(window.localStorage);
  };


  /**
   * Simple utility class to create an easy session storage API
   *
   * @class SessionStorage
   * @constructor
   * @namespace Squarespace
   * @extends Y.Base
   */
  Y.Squarespace.SessionStorage = {
    /**
     * Generic help function to store session data locally,
     * if localStorage exist it stores it there and falls back to
     * store in a cookie if it doesn't exist
     *
     * @method saveSessionData
     * @param {String} data key
     * @param {Object} data value
     */
    saveSessionData: function(key, value, expires) {
      var data = {
        value: value
      };
      if (!Y.Lang.isDate(expires)) {
        expires = null;
      } else {
        data.expires = expires.getTime();
      }


      var encodedValue = btoa(Y.JSON.stringify(data));

      if (_hasLocalStorage()) {
        window.localStorage.setItem(key, encodedValue);
      } else if (expires === null) {
        CookieCutter.set(key, encodedValue, { path: '/' });
      } else {
        CookieCutter.set(key, encodedValue, { path: '/', expires: expires });
      }
    },

    /**
     * Generic help function to read session data that was previously
     * stored.
     *
     * @method getSessionData
     * @param {String} data key
     */
    getSessionData: function(key) {
      var encodedValue;
      if (_hasLocalStorage()) {
        encodedValue = window.localStorage.getItem(key);
      } else {
        encodedValue = CookieCutter.get(key);
      }

      if (!Y.Lang.isValue(encodedValue)) {
        return null;
      }

      var data = Y.JSON.parse(atob(encodedValue));
      if (Y.Lang.isNumber(data.expires) && data.expires < new Date().getTime()) {
        if (_hasLocalStorage()) {
          window.localStorage.removeItem(key);
        }
        return null;
      }
      return data.value;
    },

    /**
     * Generic help function to removes session data that was previously
     * stored.
     *
     * @method removeSessionDate
     * @param {String} data key
     */
    removeSessionData: function(key) {
      if (_hasLocalStorage()) {
        window.localStorage.removeItem(key);
      } else {
        CookieCutter.set(key, '', { expires: new Date(0) });
      }
    }

  };
}, '1.0', { requires: [ 'base', ] });
