/*
 * This file was generated by the `create-manifests` gulp task.
 * Run `npm run gulp create-manifests` to regenerate this file.
 */
import 'src/main/webapp/universal/scripts-v6/comments.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/selector-css3/selector-css3.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/selector-css2/selector-css2.js';
import 'src/main/webapp/universal/scripts-v6/comment-data.js';
import 'src/main/webapp/universal/scripts-v6/dialog-description.js';
import 'src/main/webapp/universal/scripts-v6/login.js';
import 'src/main/webapp/universal/scripts-v6/google-domains-utils.js';
import 'src/main/webapp/universal/scripts-v6/domains/models/domain.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/model/model.js';
import 'src/main/webapp/universal/scripts-v6/model-sync-rest.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/model-sync-rest/model-sync-rest.js';
import 'src/main/webapp/universal/scripts-v6/google-domains-consent-service.js';
import 'src/main/webapp/universal/scripts-v6/session-storage.js';
import 'src/main/webapp/universal/scripts-v6/markdown.js';